import axios from 'axios';

export const favouriteOfferService = {
  saveOffer
};

function saveOffer(contact) {
  const requestOptions = {
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/favourite_offers`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      favourite_offer: {
        ...contact
      }
    }
  };

  return axios(requestOptions);
}
