import React from 'react';
import './SingleProperty.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { PropertiesService } from '../../services/PropertiesService';
import { withRouter} from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import Sticky from 'react-sticky-el';
import Moment from 'react-moment';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

class SingleProperty extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      property: {
        agent: {}
      },
      favoriteProperties:[],
      userMessage:{
        name:"",
        phone:"",
        email:"",
        message:`Bună ziua, sunt interesat de proprietatea: ${window.location.href}`,
        agreement:""
      },
      photoIndex: 0,
      openLightbox: false
    }
  }

  handleChange = event => {
    const userMessage = { ...this.state.userMessage };
    userMessage[event.target.name] = event.target.value;

    this.setState({ userMessage })
  }

  componentDidMount() {
    PropertiesService.getProperty(this.props.match.params.id)
      .then(propertyResponse => {
        this.setState({ property: propertyResponse }, () => {
          this.setState({
            mainSlider: this.mainSlider,
            navSlider: this.navSlider
          });
        });
      })
      .catch(error => {
        this.props.history.push('/');
      });

      let favoriteProperties= JSON.parse(localStorage.getItem('favoriteProperties'));

      if (!favoriteProperties){
          favoriteProperties=[];
        }
      this.setState({
          favoriteProperties
      });
  }

  openLightboxImages = (selectedImage) => {
    const images = this.state.property.images;

    const currentIndex = images.findIndex(image => image.id === selectedImage.id);

    this.setState({ photoIndex: currentIndex });
    this.setState({ openLightbox: true });
  }

  addToFavorite=(event)=>{
    event.stopPropagation();
    event.preventDefault();

    let favoriteProperties = this.state.favoriteProperties.slice();
    const propertyIndex = favoriteProperties.findIndex(property => property.id === this.state.property.id);

    if (propertyIndex !== -1) {
      favoriteProperties.splice(propertyIndex, 1);
    } else {
      favoriteProperties.push(this.state.property);
    }

    this.setState({ favoriteProperties }, () => {
      window.favoriteEventEmiter.emitEvent('addNewFavoritesProperties', [this.state.property]);
    })
  }

    render(){
        return(
            <div className='single-property-wrapper'>
              <MetaTags>
                <meta
                  property="og:title"
                  content={ this.state.property.titlu }
                />
                <meta
                  property="og:description"
                  content={ this.state.property.descriere }
                />
                <meta
                  property="og:url"
                  content={`https://www.investimobiliare.ro/proprietate/${this.state.property.idstr}`}
                />
                <meta
                  property="og:type"
                  content="website"
                />
                <meta
                  property="og:image"
                  content={ this.state.property.images && this.state.property.images.length ? this.state.property.images[0] : '' }
                />
              </MetaTags>
                <Header/>
                <div className='sp-container'>
                  <div className='sp-first-part'>
                    <div className='sp-top'>
                      <h4><span>Home / {this.state.property.tip} </span>/ {this.state.property.titlu}</h4>
                      <div className='sp-top-box'>
                        <img src='/images/heart.svg' alt='icon' className={this.state.favoriteProperties.findIndex(favoriteProperty => favoriteProperty.id === this.state.property.id) !== -1 ? 'property-heart property-heart--single highlight':'property-heart property-heart--single'} onClick={(event)=>this.addToFavorite(event)}/>
                      </div>
                    </div>
                    <div className='sp-propery'>
                      <div className='sp-title-box'>
                      <h2 className='sp-title'>{this.state.property.titlu}</h2>
                      {this.state.property.pretvanzare && <span className="property-price">{this.state.property.pretvanzare} {this.state.property.monedavanzare}</span>}
                      {this.state.property.pretinchiriere && <span className="property-price">{this.state.property.pretinchiriere} {this.state.property.monedainchiriere}</span>}
                      </div>
                      {this.state.property.pretvanzare? <h4 className='sp-type'>DE VÂNZARE</h4>:<h4 className='sp-type'>DE ÎNCHIRIERE</h4>}
                      <div className='sp-location'>
                        <img src='/images/location.svg' alt='locatie'/>
                        <h5>{this.state.property.zona}, {this.state.property.judet}</h5>
                      </div>
                    </div>
                    </div>
                    <div className='sp-desktop-container'>
                    <div className='sp-left-side'>
                      <div className="sp-property-images">
                          {this.state.property && this.state.property.images && this.state.property.images.length ? (
                            <div className="main-slider-wrapper">
                              <Carousel swipeable={true} thumbWidth={100} showIndicators={false} useKeyboardArrows={true}>
                                { this.state.property.images.length && this.state.property.images.map(image => (
                                  image.image && (
                                    <div key={image.id} onClick={() => this.openLightboxImages(image)}>
                                      <img className='property-img' src={`${process.env.REACT_APP_API_URL}/${image.image}`} alt={image.image} />
                                    </div>
                                  )
                                ))}
                              </Carousel>
                              {this.state.openLightbox && (
                                <Lightbox
                                  mainSrc={`${process.env.REACT_APP_API_URL}/${this.state.property.images[this.state.photoIndex].image}`}
                                  nextSrc={`${process.env.REACT_APP_API_URL}/${this.state.property.images[(this.state.photoIndex + 1) % this.state.property.images.length].image}`}
                                  prevSrc={`${process.env.REACT_APP_API_URL}/${this.state.property.images[(this.state.photoIndex + this.state.property.images.length - 1) % this.state.property.images.length].image}`}
                                  onCloseRequest={() => this.setState({ openLightbox: false })}
                                  onMovePrevRequest={() =>
                                    this.setState({
                                      photoIndex: (this.state.photoIndex + this.state.property.images.length - 1) % this.state.property.images.length,
                                    })
                                  }
                                  onMoveNextRequest={() =>
                                    this.setState({
                                      photoIndex: (this.state.photoIndex + 1) % this.state.property.images.length,
                                    })
                                  }
                                />
                              )}
                            </div>
                          ) : (
                            <img className='property-img' src='/images/propertyexample.jpg' alt="/images/propertyexample.jpg" />
                          )}
                          { this.state.property.property_deleted &&
                            <div className="not-available__wrapper">
                              <h3 className='not-available'>INDISPONIBIL</h3>
                            </div>
                          }
                      </div>
                    <div className='sp-second-part'>
                      <div className='sp-summary-container'>
                        <div className='sp-subtitle-box'>
                          <h3 className='sp-subtitle'>Sumar Proprietate</h3>
                          <h4>ID Proprietate: {this.state.property.idstr}</h4>
                        </div>
                        <div className='sp-summary'>
                              <div className='sp-detail'>
                                  <h3>{this.state.property.tip}</h3>
                                  <h4>Tip Proprietate</h4>
                              </div>
                              <div className='sp-detail'>
                                <div>
                                  <img src='/images/bed.png' alt='detail'/>
                                  <h3>{this.state.property.nrcamere}</h3>
                                </div>
                                  <h4>Camere</h4>
                              </div>
                              <div className='sp-detail'>
                                <div>
                                  <img src='/images/shower.png' alt='detail'/>
                                  <h3>{this.state.property.nrbai}</h3>
                                </div>
                                  <h4>Bai</h4>
                              </div>
                              <div className='sp-detail'>
                                <div>
                                  <img src='/images/surface.png' alt='detail'/>
                                  <h3>{this.state.property.suprafatautila && this.state.property.suprafatautila.slice(0, -3)}</h3>
                                </div>
                                  <h4>mp</h4>
                              </div>
                            {this.state.anconstructie ? <div className='sp-detail'>
                                <div>
                                  <img src='/images/calendar.svg' alt='detail'/>
                                  <h3>{this.state.property.anconstructie || '-'}</h3>
                                </div>
                                  <h4>An Constructie</h4>
                              </div>: '' }
                        </div>
                      </div>

                      <div className='sp-description-box'>
                        <h3 className='sp-subtitle sp-subtitle-border'>Descriere Proprietate </h3>
                        {this.state.property.descriere && this.state.property.descriere.split("\n").map(description => (
                                  <p key={Math.random()} className='general-p sp-description'>{description}</p>
                                ))}
                      </div>

                      <div className='sp-details-wrapper'>
                        <div className=' sp-second-box'>
                          <h3 className='sp-subtitle sp-lower-subtitle sp-subtitle-border'>Detalii Proprietate</h3>
                          <div className='subtitle-top'>
                              <img src='/images/calendar.svg' alt="calendar" className='calendar'/>
                              <h5>Updatat in <Moment format="MMMM Do YYYY, h:mm a">{this.state.property.datamodificare}</Moment></h5>
                          </div>
                        </div>
                        <div className='sp-info-wrapper'>
                            <div className='sp-info-first'>
                              <div className='sp-info'>
                                    <h3>Id Proprietate:</h3>
                                    <h4>{this.state.property.idstr}</h4>
                              </div>
                              <div className='sp-info'>
                                    <h3>Pret:</h3>
                                    {this.state.property.pretvanzare && <h4>{this.state.property.pretvanzare} {this.state.property.monedavanzare}</h4>}
                                    {this.state.property.pretinchiriere && <h4>{this.state.property.pretinchiriere} {this.state.property.monedainchiriere}</h4>}
                              </div>
                              <div className='sp-info'>
                                    <h3>Suprafata construita:</h3>
                                    <h4>{this.state.property.suprafataconstruita} mp</h4>
                              </div>
                              <div className='sp-info'>
                                    <h3>Suprafata totala:</h3>
                                    <h4>{this.state.property.suprafatautila} mp</h4>
                              </div>
                              <div className='sp-info'>
                                    <h3>Camere:</h3>
                                    <h4>{this.state.property.nrcamere}</h4>
                              </div>
                            </div>

                             <div className='sp-info-second'>
                              <div className='sp-info'>
                                    <h3>Bai:</h3>
                                    <h4>{this.state.property.nrbai}</h4>
                              </div>
                              <div className='sp-info'>
                                    <h3>Parcări</h3>
                                    <h4>{this.state.property.nrlocuriparcare? this.state.property.nrlocuriparcare: '-' }</h4>
                              </div>
                              <div className='sp-info'>
                                    <h3>An constructie:</h3>
                                    <h4>{this.state.property.anconstructie || '-'}</h4>
                              </div>
                              <div className='sp-info'>
                                    <h3>Tip proprietate:</h3>
                                    <h4>{this.state.property.tip}</h4>
                              </div>
                              <div className='sp-info'>
                                    <h3>Proprietate</h3>
                                    <h4>{this.state.property.devanzare? 'DE VANZARE':'DE INCHIRIERE'}</h4>
                              </div>
                            </div>
                        </div>
                        </div>
                        <div className='sp-specifications'>
                          <h3 className='sp-subtitle sp-subtitle-border'>Specificatii</h3>
                          <div className='sp-specifications-container'>
                              <div>
                                <h4 className='list-title'>Utilitati</h4>
                                <ul className='sp-list'>
                                  {this.state.property.utilitati && this.state.property.utilitati.split("),").map(utilityWithDetails => (
                                    utilityWithDetails.includes("(") ? (
                                        <li key={Math.random()}>
                                          <span>{utilityWithDetails.split("(")[0]}: </span>
                                          {utilityWithDetails.split("(")[1]}
                                        </li>
                                    ) : (
                                      utilityWithDetails.split(",").map(utility => (
                                        <li key={Math.random()}>
                                           { utility }
                                          <br />
                                        </li>
                                      ))
                                    )
                                ))}
                                </ul>
                              </div>
                              <div>
                                <h4 className='list-title'>Finisaje</h4>
                                <ul className='sp-list'>
                                  {this.state.property.finisaje && this.state.property.finisaje.split("),").map(utilityWithDetails => (
                                    utilityWithDetails.includes("(") ? (
                                        <li key={Math.random()}>
                                          <span>{utilityWithDetails.split("(")[0]}: </span>
                                          {utilityWithDetails.split("(")[1]}
                                        </li>
                                    ) : (
                                      utilityWithDetails.split(",").map(utility => (
                                        <li key={Math.random()}>
                                           { utility }
                                          <br />
                                        </li>
                                      ))
                                    )
                                ))}
                                </ul>
                              </div>
                              <div>
                                <h4 className='list-title'>Dotari</h4>
                                <ul className='sp-list'>
                                  {this.state.property.dotari && this.state.property.dotari.split("),").map(utilityWithDetails => (
                                    utilityWithDetails.includes("(") ? (
                                        <li key={Math.random()}>
                                          <span>{utilityWithDetails.split("(")[0]}: </span>
                                          {utilityWithDetails.split("(")[1]}
                                        </li>
                                    ) : (
                                      utilityWithDetails.split(",").map(utility => (
                                        <li key={Math.random()}>
                                           { utility }
                                          <br />
                                        </li>
                                      ))
                                    )
                                ))}
                                </ul>
                              </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className='sp-form-wrapper'>
                      <Sticky bottomOffset={40} boundaryElement=".sp-desktop-container">
                        <div className='sp-form-container'>
                          <div className='sp-form-top'>
                                <img src='/images/user.png' alt='user' className='sp-user-icon'/>
                                <div className='sp-user-box'>
                                  <div>
                                    <img src='/images/user.png' alt='user'/>
                                    <h4>{this.state.property.agent.nume}</h4>
                                  </div>
                                  <h4>Consilier imobiliar</h4>
                                  <h5>{this.state.property.agent.telefon}</h5>
                                </div>
                          </div>
                          <form className='sp-form'>
                            <input className='sp-field'
                                    required
                                    type="text"
                                    placeholder="Nume"
                                    name="name"
                                    value={this.state.userMessage.name}
                                    onChange={this.handleChange}/>
                              <input className='sp-field'
                                    required
                                    type="number"
                                    placeholder="Număr de telefon"
                                    name="phone"
                                    value={this.state.userMessage.phone}
                                    onChange={this.handleChange}/>
                              <input className='sp-field'
                                    required
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={this.state.userMessage.email}
                                    onChange={this.handleChange}/>
                              <textarea className='sp-field sp-textarea'
                                    rows="6"
                                    cols="10"
                                    required
                                    type="text"
                                    name="message"
                                    value={this.state.userMessage.message}
                                    onChange={this.handleChange} />

                              <div className='consent'>
                                  <label >
                                      <input id='request-field request-checkbox'
                                          required
                                          type="checkbox"
                                          name="agree"
                                          checked={this.state.agree}
                                          onChange={this.handleCheckBox} />
                                  </label>
                                  <h5 className='data-consent sp-consent'>Sunt de acord cu prelucrarea datelor.</h5>
                                  </div>
                              <div className='sp-button-box'>
                                <button type="submit" className='sp-message-button'>TRIMITE MESAJ</button>
                                <a className='sp-call' href={`tel:${this.state.property.agent.telefon}`}><button type='button' className='sp-call-button'>SUNĂ</button></a>
                                <a className='sp-wa-call' href={`https://api.whatsapp.com/send?phone=+4${this.state.property.agent.telefon}&text=Buna ziua, sunt interesat de proprietatea ${window.location.href}`} target="_blank" rel="noopener noreferrer">
                                  <button type='button' className='sp-whatsapp-button'>
                                      <img src='/images/whatsapp.png' alt='whatsapp'/>
                                      <h5>WhatsApp</h5></button>
                                </a>
                              </div>
                           </form>
                          </div>
                        </Sticky>
                      </div>
                </div>
                </div>
                <Footer/>
            </div>
        )
    }
}


export default withRouter(SingleProperty);
