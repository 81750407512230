import React from 'react';
import BannerDefault from '../../components/BannerDefault/BannerDefault';
import Footer from '../../components/Footer/Footer';
import './Favourites.scss';
import Header from '../../components/Header/Header'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { favouriteOfferService } from '../../services/FavouriteOfferService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';

class Favourites extends React.Component{
    constructor(props){
        super(props)
        this.state={
            gridBlocks:false,
            favoriteProperties:[],
            contact: {
              name:"",
              email:"",
              phone:"",
              message:"",
              properties: ''
            },
            formSubmitted: false
        }
    }
    componentDidMount(){
        const favoriteProperties=JSON.parse(localStorage.getItem('favoriteProperties'));
        this.setState({
            favoriteProperties
        }, () => {
          let contact = { ...this.state.contact };

          this.state.favoriteProperties.map( property => {
            contact.properties += `${property.idstr};`
          });

          this.setState({ contact });
        })

        this._notificationSystem = this.refs.notificationSystem;
    }

    handleChange = (event) => {
      let contact = { ...this.state.contact };

      contact[event.target.name] = event.target.value;

        this.setState({ contact });
    }

    handleContactForm = event => {
      event.preventDefault();
      this.setState({ formSubmitted: true });

      favouriteOfferService
        .saveOffer(this.state.contact)
        .then(response => {
          this._notificationSystem.addNotification({
            message: 'Am primit cererea dumneavoastra, o sa va contactam in cel mai scurt timp. Va multumim.',
            level: 'success',
            position: 'tc'
          });

          this.setState({ formSubmitted: false });

        })
        .catch(error => {
          this._notificationSystem.addNotification({
            message: 'Avem o eroare momentan pe server, va rugam sa ne contactati la numarul de telefon afisat pentru mai multe informatii.',
            level: 'error',
            position: 'tc'
          });

          this.setState({ formSubmitted: false });
        });
  }

    render(){
        return(
            <div className='fp'>
             <Header/>
            <BannerDefault title="LISTĂ PROPRIETĂȚI FAVORITE" subtitle="Proprietăți favorite" colorTitleClass='contact-title-color' bannerImage='/images/bannerImages/homepage-banner.jpg'/>
                <div className='contact__wrapper'>
                    <div className='contact-box fp-box'>
                        <h3 className='fp__description'><span>Adaugă la favorite</span> proprietățile care prezintă cel mai mult interes pentru tine <span>(apasă butonul ♡)</span></h3>
                        <h3 className='fp__description'><span>Completează formularul </span>de mai jos cu datele tale și apasă Trimite.</h3>
                        <h3 className='fp__description'>Un agent te va contacta în cel mai scurt timp și îți va oferi<span> mai multe detalii</span> despre proprietățile din lista ta de favorite.</h3>
                        <div className="contact-form" id="contact">
                            <form onSubmit={this.handleContactForm}>
                                <h4 className='label'>Nume*</h4>
                                <input type="text"

                                        required
                                        placeholder="Tastează numele tau"
                                        name="name"
                                        value={this.state.contact.name}
                                        onChange={this.handleChange}/>
                                <h4 className='label'>Email*</h4>
                                <input type="email"
                                        required
                                        placeholder="Tastează adresa ta de email"
                                        name="email"
                                        value={this.state.contact.email}
                                        onChange={this.handleChange}/>
                                 <h4 className='label'>Telefon*</h4>
                                <input type="number"
                                        required
                                        placeholder="Tastează numar de telefon"
                                        name="phone"
                                        value={this.state.contact.phone}
                                        onChange={this.handleChange}/>
                                 <h4 className='label'>Mesaj*</h4>
                                <textarea cols="30"
                                          rows="5"
                                          required
                                          className='contact-text-area'
                                          placeholder='Tastează mesajul tau'
                                          name="message"
                                          value={this.state.contact.message}
                                          onChange={this.handleChange}
                                          />
                                          <h4 className='label'>Mesajul catre noi va include si lista de proprietati favorite.</h4>
                                <button type="submit" className="general-button send-message" disabled={this.state.formSubmitted}>Trimite Mesaj</button>
                            </form>
                        </div>
                        <h2 className='fp__title'>Listă Proprietăți Favorite</h2>
                        <div className='properties-wrapper properties-grid-wrapper fp__grid-wrapper'>
       {this.state.favoriteProperties.map( property => (
         <Link key={property.id} className='individual-property individual-grid-property fp__property' to={`/proprietate/${property.idstr}`}>
           <div className="property-wrapper property-grid-wrapper fp__property-wrap">
             <div className='img-property img-grid-property'>
               {
                 property.images && property.images.length ? (
                   <img className="property-main-image fp__main-img" src={`${process.env.REACT_APP_API_URL}/${property.images[0].image}`} alt="Property Main" />
                 ) : (
                   <img className="property-main-image fp__main-img" src="/images/propertyexample.jpg" alt="Property Main" />
                 )}

               <div>
                 {property.devanzare || property.deinchiriat ? ('') : (
                   <div className='properties-unavailable'>
                     <h4 className='properties-unavailable-writing'>NU MAI ESTE VALABIL</h4>
                   </div>
                 )}
               </div>

             </div>
                 <div className='grid-details'>
                   <div className='grid-details-top'>
                     <div className='price-share-grid'>
                       {property.pretvanzare && <h3 className='price'>{property.pretvanzare} {property.monedavanzare}</h3>}
                       {property.pretinchiriere && <h3 className='price'>{property.pretinchiriere} {property.monedainchiriere}</h3>}
                    </div>
                   <div className='tranzaction-type tranzaction-grid-type fp__tranzaction-type'>
                     { property.devanzare && (
                       <h4 className='tranzaction'>VÂNZARE</h4>
                     )}
                     { property.deinchiriat && (
                       <h4 className='tranzaction'>ÎNCHIRIERE</h4>
                     )}
                 </div>
                 </div>
               <h4 className='property-title property-grid-title'>{property.titlu}</h4>
                   <div className='property-adress'>

                       <p>{property.zona}, {property.judet}</p>
                   </div>

             <div className='property-details property-grid-details-info'>
                 <div className='property-info property-grid-detail'>
                     <img src='/images/bed.png' alt='property' className='detail-icon'/>
                     <p className='detail-item'>{property.nrcamere ? property.nrcamere : '-'}</p>
                 </div>

                 <div className='property-info property-grid-detail'>
                     <img src='/images/shower.png' alt='property' className='detail-icon'/>
                     <p className='detail-item'>{property.nrbai ? property.nrbai : '-'}</p>
                 </div>

                 {/*<div className='property-info property-grid-detail'>
                     <p className='detail-item'>Id Ofertă</p>
               <p className='detail-item'>{property.idstr}</p>
                 </div>*/}
                 <div className='property-info property-grid-detail'>
                   <img src='/images/surface.png' alt='property' className='detail-icon'/>
                     <p className='detail-item'>{property.suprafataconstruita && property.suprafataconstruita.slice(0, -3)} mp</p>
                 </div>
             </div>
             <h4 className='property-type property-grid-type fp__grid-type'>{property.tip.toUpperCase()}</h4>
             <div className='grid-bottom'>
               <div className='property-extra-info property-grid-extra-info'>
                   <div className='property-extra-box'>
                     <img src='/images/user.png' alt='user'/>
                     <h4>{property.agent.nume}</h4>
                   </div>
                   <div className='property-extra-box'>
                     <img src='/images/clock.png' alt='clock'/>
                     <h4> <Moment locale="ro" fromNow>{property.datamodificare}</Moment></h4>
                   </div>
               </div>
             </div>
             </div>
             </div>


             </Link> ))}

             </div>
            </div>
         </div>

         <NotificationSystem
           ref="notificationSystem"
           style={NotificationSystemStyle}
         />
         <Footer />
        </div>
        )
    }
}

export default Favourites;
