import React from 'react';
import './FAQ.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import BannerDefault from '../../components/BannerDefault/BannerDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

class FAQ extends React.Component{
    constructor(){
        super()
        this.state={
            open:false
        }
    }
    togglePanel(e){
        this.setState({open: !this.state.open})
        }
    render(){
        return(
            <div>
                <Header/>
                <BannerDefault title="Servicii" subtitle="Servicii" bannerImage='/images/bannerImages/servicii-banner.jpg'/>
                <div className='faq-area'>
                    <p className='general-p about-p'><b>Agentia Invest Imobiliare</b> oferă servicii profesionale în domeniul imobiliar, iar satisfacția clienților noștri ne poziționează în topul agențiilor din județul Iași.</p>
                    <p className='general-p about-p'>Tranzacțiile imobiliare reprezintă o decizie importantă în viața fiecărei persoane astfel venim  în completarea nevoilor dumneavoastră cu soluții profesionale personalizate ce includ:</p>
                    <ul className='services-list'>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faArrowRight} className='contact-svg service-icon'/>
                            <li className='service-item'>consultanța imobiliară</li>
                        </div>
                         <div className='list-type'>
                            <FontAwesomeIcon icon={faArrowRight} className='contact-svg service-icon'/>
                            <li className='service-item'>consultanță financiară</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faArrowRight} className='contact-svg service-icon'/>
                            <li className='service-item'>consultanță juridică</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faArrowRight} className='contact-svg service-icon'/>
                            <li className='service-item'>închirieri și vanzări apartamente, case, spații și terenuri</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faArrowRight} className='contact-svg service-icon'/>
                            <li className='service-item'>administrare proprietați</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faArrowRight} className='contact-svg service-icon'/>
                            <li className='service-item'>evaluare imobiliară</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faArrowRight} className='contact-svg service-icon'/>
                            <li className='service-item'>întocmire certificat energetic</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faArrowRight} className='contact-svg service-icon'/>
                            <li className='service-item'>întocmire documentație pentru tranzacție</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faArrowRight} className='contact-svg service-icon'/>
                            <li className='service-item'>servicii de topografie și cadastru</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faArrowRight} className='contact-svg service-icon'/>
                            <li className='service-item'>orice serviciu necesar realizarii unei tranzacții imobiliare în condiții de legalitate.</li>
                        </div>
                    </ul>
                    <p className='general-p about-p service-final'>Facem din scopul dumneavoastră un scop comun ca să aveți parte de o experiență frumoasă și fără complicații alături de noi, într-un timp cât mai scurt!</p>
                </div>
                <Footer/>
            </div>
        )
    }
}


export default FAQ;
