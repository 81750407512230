import React, { Component } from 'react';
import './HamburgerLink.scss';
import { Link } from 'react-router-dom';

class HamburgerLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSubMenu: false
    };
  }

  openSubmenu() {
    this.setState({ activeSubMenu: true });
  }

  closeSubmenu() {
    this.setState({ activeSubMenu: false });
  }

  render() {
    return (
      <li className="hamburger__link">
        { this.props.phoneLink ? (
          <a href={`tel:${this.props.label}`}>{this.props.label}</a>
        ) : (
          <Link to={this.props.link}>
            <span className="link-title"> {this.props.label} </span>
          </Link>
        )}
      </li>
    );
  }
}

export default HamburgerLink;
