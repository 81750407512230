import React from 'react'
import './About.scss'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import BannerDefault from '../../components/BannerDefault/BannerDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'


class About extends React.Component{
    render(){
        return(
            <div className='about'>
                <Header/>
                <BannerDefault title="DESPRE NOI" subtitle="Despre noi" colorTitleClass='about-title-color' bannerImage="/images/bannerImages/servicii-banner.jpg"/>
                <div className='about-wrapper'>
                    <div className='about-top'>
                        <div className='about-introduction'>
                            <section className='about-description'>
                                <h3 className='adress-title about-title'>Despre Invest Imobiliare Iași</h3>
                                <h5 className='welcome-subtitle about-p'>Echipa Invest imobiliare se identifică cu o experiența vastă în domeniul imobiliar evidențiată de respectul și seriozitatea cu care este tratat fiecare client în parte.  </h5>
                                <p className='general-p about-p'>  Serviciile de calitate, transparența și profesionalismul ne recomandă pe piața imobiliară! Punem accent pe valori, oameni și principii, iar normele de conduită și o ținută morală înaltă fac parte din conceptul care stă la baza înființării acestei companii. </p>
                                <p className='general-p about-p'>Venim zilnic în completarea nevoilor dumneavoastră cu servicii de vânzări, cumpărări și închirieri de imobile din întreg județul Iași. Răspundem prompt  și căutăm soluții care satisfac până și cerințele celor mai pretențioși clienți. </p>
                                <p className='general-p about-p'>Ne place ceea ce facem, suntem corecți, empatici și acționăm cinstit, de asta vrem să-ți dăm timp să-ți trăiești prezentul!</p>
                                <p className='general-p about-p'>Noi suntem aici ca să gândim în viitor pentru tine! </p>
                            </section>
                        </div>
                        <div className='about-introduction-image'>
                          <img src='images/bannerImages/oferta-ta-banner.jpg' className='about-img' alt='invest imobiliare'/>
                        </div>
                    </div>
                        <h3 className='adress-title about-title about-second-title'>Servicii Invest Imobiliare Iasi</h3>
                        <p className='general-p about-p'> <b>Agentia Invest Imobiliare</b> oferă servicii profesionale în domeniul imobiliar, iar satisfacția clienților noștri ne poziționează în topul agențiilor din județul Iași.</p>
                                <p className='general-p about-p'>Tranzacțiile imobiliare reprezintă o decizie importantă în viața fiecărei persoane astfel venim în completarea nevoilor dumneavoastră cu soluții profesionale personalizate ce includ: </p>
                                <ul className='services-list'>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faChevronRight} className='contact-svg service-icon'/>
                            <li className='service-item'>consultanța imobiliară</li>
                        </div>
                         <div className='list-type'>
                            <FontAwesomeIcon icon={faChevronRight} className='contact-svg service-icon'/>
                            <li className='service-item'>consultanță financiară</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faChevronRight} className='contact-svg service-icon'/>
                            <li className='service-item'>consultanță juridică</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faChevronRight} className='contact-svg service-icon'/>
                            <li className='service-item'>închirieri și vanzări apartamente, case, spații și terenuri</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faChevronRight} className='contact-svg service-icon'/>
                            <li className='service-item'>administrare proprietați</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faChevronRight} className='contact-svg service-icon'/>
                            <li className='service-item'>evaluare imobiliară</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faChevronRight} className='contact-svg service-icon'/>
                            <li className='service-item'>întocmire certificat energetic</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faChevronRight} className='contact-svg service-icon'/>
                            <li className='service-item'>întocmire documentație pentru tranzacție</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faChevronRight} className='contact-svg service-icon'/>
                            <li className='service-item'>servicii de topografie și cadastru</li>
                        </div>
                        <div className='list-type'>
                            <FontAwesomeIcon icon={faChevronRight} className='contact-svg service-icon'/>
                            <li className='service-item'>orice serviciu necesar realizarii unei tranzacții imobiliare în condiții de legalitate.</li>
                        </div>
                    </ul>
                    <h5 className='welcome-subtitle about-p'>Facem din scopul dumneavoastră un scop comun ca să aveți parte de o experiență frumoasă și fără complicații alături de noi, într-un timp cât mai scurt! </h5>
                    <p className='general-p about-p'>Cu stima,</p>
                                <p className='general-p about-p italic-p'>Echipa Invest Imobiliare</p>
                    <div className='agent-presentation'>
                    <h1 className='agent-title'>AGENȚII NOȘTRI</h1>
                    <div className='agents'>
                        <div className='agent-individual'>
                            <img className='agents-pic about-pic'src='/images/agent-1.jpg' alt="About Agent"/>
                            <h5 className='agent-name'>Mark Anthony</h5>
                            <p className='general-p profession'>Real Estate Agent</p>
                            <div className='social-media'>
                            <FontAwesomeIcon icon={faFacebookF} className='social-media-icon' />
                                <FontAwesomeIcon icon={faLinkedinIn} className='social-media-icon'/>
                        </div>
                    </div>

                        <div className='agent-individual'>
                            <img className='agents-pic about-pic'src='/images/agent-2.jpg' alt="About Agent"/>
                            <h5 className='agent-name'>Ellie Abbey</h5>
                            <p className='general-p profession'>Real Estate Agent</p>
                            <div className='social-media'>
                                <FontAwesomeIcon icon={faFacebookF} className='social-media-icon'/>
                                <FontAwesomeIcon icon={faLinkedinIn} className='social-media-icon'/>
                            </div>
                        </div>

                    </div>
                    </div>
            </div>
            <Footer/>
            </div>
        )
    }
}
export default About
