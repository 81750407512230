import React from 'react';
import './ContactForm.scss';
import { ContactFormService } from '../../services/ContactFormService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import ReCAPTCHA from "react-google-recaptcha";
import { googleVerificationService } from '../../services/GoogleVerificationService';

class ContactForm extends React.Component{
    constructor(props){
        super(props);

        this.state={
            fullName:"",
            email:"",
            phone:"",
            message:"",
            formSubmitted: false,
            googleRecapchaValue: ''
        };

        this.recaptchaRef = React.createRef();
        this.handleChange=this.handleChange.bind(this);
    }

    componentDidMount() {
      this._notificationSystem = this.refs.notificationSystem;
    }

    handleChange(event){
        this.setState({
            [event.target.name]:event.target.value
        });
    }

    handleContactForm = event => {
      event.preventDefault();
      this.setState({ formSubmitted: true });

      googleVerificationService.verifyToken(this.state.googleRecapchaValue, true).then(response => {
        if (response.data.success) {
          ContactFormService
            .saveContact(this.state)
            .then(response => {
              this._notificationSystem.addNotification({
                message: 'Am primit cererea dumneavoastra, o sa va contactam in cel mai scurt timp. Va multumim.',
                level: 'success',
                position: 'tc'
              });

              this.setState({ formSubmitted: false });
              this.recaptchaRef.current.reset();

            })
            .catch(error => {
              this._notificationSystem.addNotification({
                message: 'Avem o eroare momentan pe server, va rugam sa ne contactati la numarul de telefon afisat pentru mai multe informatii.',
                level: 'error',
                position: 'tc'
              });

              this.setState({ formSubmitted: false });
              this.recaptchaRef.current.reset();

            });
      } else {
        this._notificationSystem.addNotification({
          message:
            'Va rugam completati ReCAPTCHA.',
          level: 'error',
          position: 'tc'
        });
        this.setState({ formSubmitted: false });
      }
    });
  }

  onChange = value => {
    this.setState({ googleRecapchaValue: value });
  }

    render(){
        return(
        <div className="contact-form" id="contact">
            <form onSubmit={this.handleContactForm}>
                <h4 className='label'>Nume*</h4>
                <input type="text"

                        required
                        placeholder="Tastează numele tau"
                        name="fullName"
                        value={this.state.fullName}
                        onChange={this.handleChange}/>
                <h4 className='label'>Email*</h4>
                <input type="email"
                        required
                        placeholder="Tastează adresa ta de email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}/>
                 <h4 className='label'>Telefon*</h4>
                <input type="number"
                        required
                        placeholder="Tastează numar de telefon"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleChange}/>
                 <h4 className='label'>Mesaj*</h4>
                <textarea cols="30"
                          rows="5"
                          required
                          className='contact-text-area'
                          placeholder='Tastează mesajul tau'
                          name="message"
                          value={this.state.message}
                          onChange={this.handleChange}
                          />
                          <ReCAPTCHA
                            className="google-recapha"
                            ref={this.recaptchaRef}
                            sitekey="6LcqCjgaAAAAALt4ed5ngm6EPFSvgPPnj-EuFECE"
                            onChange={this.onChange}
                          />
                <button type="submit" className="general-button send-message" disabled={this.state.formSubmitted}>Trimite Mesaj</button>
            </form>
            <NotificationSystem
              ref="notificationSystem"
              style={NotificationSystemStyle}
            />
        </div>
    )}
}


export default ContactForm;
