import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import InputLabel from '@material-ui/core/InputLabel';
import './AttachementField.scss';

class AttachementField extends Component{
    render(){
        const dropzoneStyle = {
            width: '100%',
            height: '70px',
            border: 'dashed 0.7px #becad6',
            cursor: 'pointer'
          };

          const dropzoneDisabledStyle = {
            width: '100%',
            height: '100%',
            border: 'dashed 0.7px #becad6',
            borderRadius: 5,
            background: 'rgba(255, 255, 255, .5)',
            paddingLeft: '10px'
          };
        return(
            <div className="attachment-form__upload-content">
                <InputLabel>{this.props.attachmentLabel}</InputLabel>
                <Dropzone
                    accept={this.props.type}
                    multiple={true}
                    onDrop={this.props.onDrop}
                    disabled={this.props.disabledDocuments}
                    style={dropzoneStyle}
                    disabledStyle={dropzoneDisabledStyle}
                >
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="attachment-form__upload-files">
                        <div className="drop-instruction">
                          <h3 className="upload-indication">
                              Selecteaza maxim 7 imagini
                            </h3>
                          <div className='drop-icons-box'>
                          <img src='/images/upload.svg' className="upload-icon" alt='upload-icon' />

                         </div>
                        </div>
                        {this.props.documents && this.props.documents.length ?
                          (
                            <div className="attachment-form__existing-files">
                              <ul className="attachment-form__file-list">
                              { this.props.documents.map(document => (
                                <li
                                  className="attachment-form__file"
                                  key={
                                    document.name
                                  }
                                >
                                  {document.name}
                                  <div className="attachment-form__remove-files">
                                    <button
                                      type="button"
                                      className="general-button"
                                      onClick={() => this.props.removeDocument(document)}
                                    >
                                    <h4 className='attachment-form__document-delete'>
                                      Șterge Fisier</h4>
                                    </button>
                                  </div>
                                </li>
                              )) }

                              </ul>
                            </div>
                          ): (null)}
                      </div>
                    </div>
                  </section>
                )}
        </Dropzone>
      </div>
        )
    }
}

export default AttachementField;
