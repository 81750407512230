import React from 'react'
import './EstateTypes.scss';
import { withRouter} from 'react-router-dom';

class EstateTypes extends React.Component{
    searchProperties = (type) => {
      this.props.history.push({
        pathname: 'proprietati',
        search: `?tip=${type}`
      });
    }

    redirectToPage = path => {
      this.props.history.push(path);
    }

    render(){
       return(
           <div className="container-residentials">
                <h2 className="search-question">TE AJUTĂM SĂ CAUȚI:</h2>
                 <div className="residential-types">
                     <div className="residential-info" onClick={() => this.searchProperties('apartament')}>
                        <img className="estate"src="/images/apartaments.svg" alt="Apartaments"/>
                        <h4 className="residential-name">APARTAMENTE</h4>
                        <p className="residential-description">Ești în căutarea unui apartament nou sau vechi? Zona centrală sau departe de zgomotul orașului? Iți prezentăm portofoliul nostru actualizat zilnic în funcție de nevoile fiecărui client!  </p>
                    </div>

                    <div className="residential-info" onClick={() => this.searchProperties('casavila')}>
                        <img className="estate"src="/images/house.svg" alt="House"/>
                        <h4 className="residential-name">CASE/VILE</h4>
                        <p className="residential-description">Vrei să te muți la casă, dar încă nu te-ai hotărât unde? Suntem zi de zi în mijlocul zonelor de dezvoltare din proximitatea orașului și adunăm oferte pe placul tuturor! Intră și vezi! </p>
                    </div>

                    <div className="residential-info" onClick={() => this.searchProperties('teren')}>
                        <img className="estate"src="/images/commercial.svg" alt="Teren"/>
                        <h4 className="residential-name">TERENURI</h4>
                        <p className="residential-description">Vrei să-ți construiești propria casă, dar nu ai încă un teren? Intră și găsește oferta care ți se potrivește. Avem terenuri de vânzare în toate zonele din Iași!</p>
                    </div>

                    <div className="residential-info" onClick={() => this.searchProperties('teren')}>
                        <img className="estate"src="/images/spatii-icon.png" alt="Spatii"/>
                        <h4 className="residential-name">SPAȚII</h4>
                        <p className="residential-description">Vrei să te dezvolți? Iți punem la dispoziție spații comerciale, birouri și spatii industriale în zona perfectă pentru tine și afacerea ta!</p>
                    </div>
                </div>
                <div className="estate-type-contact-button">
                  <button className="general-button specific-search-button about-button" onClick={() => this.redirectToPage('despre-noi')}>Afla Mai Multe Despre Noi Si Serviciile Noastre</button>
                </div>
        </div>
       )
    }
}



export default withRouter(EstateTypes);
