import React from 'react';
import './Header.scss';
import {Link} from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import Menu from './Menu/Menu';

class Header extends React.Component{
    render(){
        return(
            <div className='header'>

                <Link to='/' className="logo"><img className="logo-img" src="/images/investImobiliareLogo.png" alt="Logo Invest"/></Link>
                <HamburgerMenu />
                <Menu />
            </div>
        )
    }
}


export default Header;
