import React from 'react';
import './Article.scss';
import Header from '../../components/Header/Header'
import BannerDefault from '../../components/BannerDefault/BannerDefault';
import Footer from '../../components/Footer/Footer';
import articlesJson from './forArticle.json';

class Article extends React.Component{
    constructor(props){
        super(props)
        this.state={
            articles:articlesJson
        }
    }
    render(){
        return(
            <div className='article'>
             <Header/>
                <BannerDefault title="BLOG INVEST IMOBILIARE IAȘI" 
                subtitle="Exemplu Titlu Articol" colorTitleClass='blog__title-color' 
                bannerImgHeight='blog__banner' 
                bannerHeight='blog__banner-heigth'
                textImg='blog__banner-text'
                bannerImage='/images/news.jpg'/>
            
            <div>
            {this.state.articles.map(article=>(
                <div className='article__container'>
                    <div className='article__wrapper'>
                        <h1 className='article__title'>{article.title}</h1>
                        <h3 className='article__subtitle'>{article.subtitle}</h3>
                        <p className='article__paragraph'>{article.paragraph1}</p>
                        <img src={article.mainImg} className='article__main-img' alt='main'/>
                        <p className='article__paragraph'>{article.paragraph2}</p>
                        <img src={article.secondaryImg} alt='secondary' className='article__secondary-img'/>
                        <p className='article__paragraph'>{article.paragraph3}</p>
                        <h3 className='article__subtitle'>{article.subtitle}</h3>
                        <p className='article__paragraph'>{article.paragraph3}</p>
                    </div>
                </div>
                ))}
            </div>
            <Footer/>
            </div>
            
        )
    }
}
export default Article;