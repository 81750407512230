import React from 'react';
import Header from '../../components/Header/Header'
import BannerDefault from '../../components/BannerDefault/BannerDefault';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../components/Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import './Contact.scss';
import ContactMap from '../../components/GoogleMaps/ContactMap/ContactMap';

class Contact extends React.Component{
    render(){
        return(
            <div className="contact-container">
            <Header/>
            <BannerDefault title="CONTACT INVEST IMOBILIARE IAȘI" subtitle="Contact" colorTitleClass='contact-title-color' bannerImage='/images/bannerImages/contact-banner.jpg'/>
            <div className='contact-info-container'>
                <div className='contact-wrapper'>
                <div className='contact-box'>
                    <h3 className='contact-description'>Completați formularul de mai jos pentru a primi mai multe informații despre ofertele noastre . Veți fi contactat în cel mai scurt timp de către unul din agenții noștri.</h3>
                    <ContactForm/>
                </div>
                    <div className='contact-box-details'>
                        <h3 className='detail-contact detail-contact-indication'>Ne puteți contacta:</h3>
                            <div className="contact-methods">
                            <h3 className='detail-contact'>Număr de telefon:</h3>
                            <div className='contact-box-small'>
                                <FontAwesomeIcon icon={faPhone} className='contact-svg'/>
                                <p className='general-p'> <a className='contact-link'href="tel:0752979059">0752979059</a></p>
                            </div>
                            <div className='contact-box-small'>
                                <FontAwesomeIcon icon={faPhone} className='contact-svg'/>
                                <p className='general-p'> <a className='contact-link'href="tel:0749455951">0749455951</a></p>
                            </div>
                            <h3 className='detail-contact'>Adresa de email:</h3>
                            <div className='contact-box-small'>
                                <FontAwesomeIcon icon={faEnvelope} className='contact-svg'/>
                                <p className='general-p'><a className='contact-link' href="mail:office@investimobiliare.ro">office@investimobiliare.ro</a></p>
                            </div>
                            <h3 className='detail-contact'>Adresă Sediu Central:</h3>
                            <div className='contact-box-small'>
                                <FontAwesomeIcon icon={faMapMarker} className='contact-svg'/>
                                <p className='general-p'>Bulevardul Tutora, nr. 4, Bloc 4, scara D, ap.4 Iasi</p>
                            </div>
                    </div>
                </div>
                </div>
                <div className="contact_location">
                  <ContactMap />
                </div>
            </div>
            <Footer/>
        </div>
        )
    }
}


export default Contact;
