import React from 'react';
import './Offer.scss';
import Header from '../../components/Header/Header';
import BannerDefault from '../../components/BannerDefault/BannerDefault';
import RequestForm from '../../components/RequestForm/RequestForm';
import Footer from '../../components/Footer/Footer';
class Offer extends React.Component{
    render(){
        return(
            <div className='offer'>
                <Header/>
                <BannerDefault title='Listează Proprietate Cu Invest Imobiliare Iași' subtitle='Oferta ta' colorTitleClass='offer-title-color' bannerImage='/images/bannerImages/oferta-ta-banner.jpg'/>
                <div className='about-wrapper offer-wrapper'>
                    <div className='offer__top'>
                        <h5 className='welcome-subtitle offer-subtitle'>Adăugați aici oferta dumneavoastră și veți fi contactat de un agent în cel mai scurt timp.</h5>
                        <RequestForm type="offer"/>  
                    </div>
                        <img src='/images/request-img.jpg' alt="Offer" className='offer-img'/>
                
                </div>
                <Footer/>
            </div>
        )
    }
}
export default Offer;
