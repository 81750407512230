import React from 'react';
import './BannerDefault.scss';

class BannerDefault extends React.Component{
    render(){
        return(
            <div className={'banner-default ' +this.props.bannerHeight}>
                <img src={this.props.bannerImage} alt={this.props.bannerImage} className={this.props.bannerImgHeight} />
                <div className={"banner-image-text "+this.props.textImg}>
                  <h1 className={'title-header '  + this.props.colorTitleClass || ''}>{this.props.title}</h1>
                  <h3 className={'subtitle-header '+ this.props.colorTitleClass || ''}>Acasă   ›   {this.props.subtitle}</h3>
                </div>
            </div>
        )
    }
}

export default BannerDefault;
