export const PropertiesService = {
  getProperties,
  getProperty,
  search,
  getLatestProperties,
  contactSeller
};

function getProperties() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/properties`, requestOptions).then(
    handleResponse
  );
}

function getLatestProperties() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/properties/latest`, requestOptions).then(
    handleResponse
  );
}

function getProperty(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/properties/get_property/${id}`, requestOptions).then(
    handleResponse
  );
}

function search(query, pageNumber) {
  const requestOptions = {
    method: 'GET'
  };

  let url = `${process.env.REACT_APP_API_URL}/properties/search?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  Object.keys(query).forEach(queryKey => {
    if (Array.isArray(query[queryKey])) {
      query[queryKey].map(arrayValue => {
        url += `&${queryKey}[]=${arrayValue}`;
      });
    } else {
      url += `&${queryKey}=${query[queryKey]}`;
    }
  });

  return fetch(url, requestOptions).then(handleResponse);
}

function contactSeller(contactForm) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(contactForm)
  };

  let url = `${process.env.REACT_APP_API_URL}/properties/contact_seller`

  return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  if (response.status === 401) {
    const error = response.statusText;

    return Promise.reject(error);
  }

  return response.json().then(data => {
    if (!response.ok) {
      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
