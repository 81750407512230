import React from "react";
import './Homepage.css';
import Header from '../../components/Header/Header';
import './Homepage.css';
import Banner from '../../components/Banner/Banner';
import Search from '../../components/Search/Search';
import EstateTypes from '../../components/EstateTypes/EstateTypes';
import Footer from '../../components/Footer/Footer';
import { PropertiesService } from '../../services/PropertiesService';
import Properties from '../../components/Properties/Properties';

class Homepage extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      featuredProperties: [],
      latestProperties: [],
      gridBlocks:true
    }
  }

  componentDidMount() {
    PropertiesService.search({pole: 1})
      .then(propertiesResponse => {
        this.setState({ featuredProperties: propertiesResponse.properties.slice(0, 6) })
      })
      .catch(error => {
        console.log(error);
      })

    PropertiesService.getLatestProperties()
      .then(propertiesResponse => {
        this.setState({ latestProperties: propertiesResponse.slice(0, 6) })
      })
      .catch(error => {
        console.log(error);
      })
  }

    render(){
        return(
            <div className="container-wrap">
             <Header />
             <Banner bannerImage="/images/bannerImages/homepage-banner.jpg" />
             <div className='search-container'>
              <Search />
             </div>

              <div className='homepage-bottom-wrapper'>
                <div className='homepage-latest-properties'>
                {this.state.latestProperties.length > 0 ? <Properties gridStyle={this.state.gridBlocks} properties={this.state.latestProperties} title="ULTIMELE OFERTE IMOBILIARE"/> : <div></div>}
                </div>
                <EstateTypes />

             { this.state.featuredProperties.length > 0 ? <Properties gridStyle={this.state.gridBlocks}  properties={this.state.featuredProperties} title="OFERTE PROMOVATE"/> : <div></div> }
             </div>
             <Footer />
            </div>
        )
    }
}

export default Homepage;
