export const ContactFormService = {
  saveContact
};

function saveContact(contact) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(prepareContactInfo(contact))
  };

  return fetch(`${process.env.REACT_APP_API_URL}/contacts`, requestOptions).then(
    handleResponse
  );
}

function prepareContactInfo(contact) {
  return {
    ...contact,
    full_name: contact.fullName
  }
}

function handleResponse(response) {
  if (response.status === 401) {
    const error = response.statusText;

    return Promise.reject(error);
  }

  return response.json().then(data => {
    if (!response.ok) {
      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
