import axios from 'axios';

export const ClientOfferService = {
  createOffer
};

function createOffer(clientOffer) {
  const requestOptions = {
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/client_offers`,
    headers: {
      Accept: 'application/json'
    },
    data: buildentryData(clientOffer)
  };

  return axios(requestOptions);
}

function buildentryData(clientOffer) {
    let formData = new FormData();

    console.log(clientOffer);

    formData.append('client_offer[full_name]', clientOffer.fullName);
    formData.append('client_offer[email]', clientOffer.email);
    formData.append('client_offer[phone]', clientOffer.phone);
    formData.append('client_offer[property_type]', clientOffer.propertyType);
    formData.append('client_offer[adress]', clientOffer.adress);
    formData.append('client_offer[city]', clientOffer.city);
    formData.append('client_offer[description]', clientOffer.descripton);

    let documents = clientOffer.client_image_data;
    for (let i = 0; i < documents.length; i++) {
      let file = documents[i];

      if (Object.keys(file).length === 0) {
        continue;
      }

      if (file.id) {
        formData.append('client_offer[client_image_data][]', file.id);
      } else {
        formData.append('client_offer[client_image_data][]', file, file.name);
      }
    }

    return formData;
  }
