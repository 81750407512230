import React from 'react';
import './Favourites.scss';
import {Link} from 'react-router-dom';
import '../CompareBar/CompareBar.scss';

class Favourites extends React.Component{
    constructor(props){
        super(props)
        this.state={
            activeFavorites:false,
            favoriteProperties: props.favoriteProperties || []
        }
    }

    componentDidMount(){
        let favoriteProperties=JSON.parse(localStorage.getItem('favoriteProperties'));
        if (!favoriteProperties){
            favoriteProperties=[];
          }
        this.setState({
            favoriteProperties
        });

        window.favoriteEventEmiter.addListener('addNewFavoritesProperties', this.addNewFavoriteProperties);
    }

    componentWillUnmount() {
      window.favoriteEventEmiter.removeListener('addNewFavoritesProperties', this.addNewFavoriteProperties);
    }

    addNewFavoriteProperties = addedProperty => {
      let favoriteProperties = this.state.favoriteProperties.slice();
      const propertyIndex = favoriteProperties.findIndex(property => property.id === addedProperty.id);

      if (propertyIndex !== -1) {
        favoriteProperties.splice(propertyIndex, 1);
      } else {
        favoriteProperties.push(addedProperty);
      }

      this.setState({ favoriteProperties }, () => {
        localStorage.setItem('favoriteProperties', JSON.stringify(favoriteProperties));
      });
    }

    forceReload = () => {
      this.props.history.listen((location, action) => {});
    }

    openFavorites = () => {
        this.setState({ activeFavorites: true });
        document.body.classList.add('has-active-favorites');
      };
      closeFavorites = () => {
        this.setState({ activeFavorites: false });
        document.body.classList.remove('has-active-favorites');
      };

      deleteFavoredProperty= currentProperty =>{
        let favoriteProperties = this.state.favoriteProperties.slice();
        const propertyIndex = favoriteProperties.findIndex(property => property.id === currentProperty.id);

        if (propertyIndex !== -1) {
          favoriteProperties.splice(propertyIndex, 1);
          this.setState({ favoriteProperties }, () => {
            localStorage.setItem('favoriteProperties', JSON.stringify(favoriteProperties));
          });
        }
      }

    render(){
        return(
            <div className='fav'>
                <div className='fav__icon-box'>
                    <img src='/images/heart.png' alt='favorite' className='heart-icon'  onClick={this.openFavorites}/>
                    <h5>{this.state.favoriteProperties.length}</h5>
                </div>
               <div className={'fav-wrapper '+(this.state.activeFavorites ? 'active' :'')}>
               <img src='/images/heart.png' alt='favorite' className={this.state.activeFavorites ? 'heart-icon-favs': 'heart-icon-fav-none'}/>
               <div className='fav-inner'>
                    <h2 className='fav-title'>Proprietăți favorite</h2>
                    <div className='cp-wrapper'>
                        { this.state.favoriteProperties.map(property=>(
                             <div key={property.id} className='cp-image-box'>
                                <Link to={`/proprietate/${property.idstr}`} onClick={this.forceReload}>
                                  <img src={`${process.env.REACT_APP_API_URL}/${property.images[0].image}`} alt='main img' className='cp-property-img'/>
                                </Link>
                                <img src='/images/close-white-bordered.png' alt='delete' className='cp-delete' onClick={()=>this.deleteFavoredProperty(property)}/>
                            </div>
                        ))}
                    </div>
                    <Link to='/favorite'><button className='fav-see-list'>Vezi lista</button></Link>
                    <h4 className='fav-close' onClick={this.closeFavorites}>Închide</h4>
                </div>
               </div>
            </div>
        )
    }
}

export default Favourites;
