import React from 'react';
import './Blog.scss';
import articlesJson from './articles.json';
import Header from '../../components/Header/Header'
import BannerDefault from '../../components/BannerDefault/BannerDefault';
import Footer from '../../components/Footer/Footer';

class Blog extends React.Component{
    constructor(props){
        super(props)
        this.state={
            articles:articlesJson
        }
    }
    render(){
        return(
            <div className='blog'>
                <Header/>
                <BannerDefault title="BLOG INVEST IMOBILIARE IAȘI" 
                subtitle="Blog" colorTitleClass='blog__title-color' 
                bannerImgHeight='blog__banner' 
                bannerHeight='blog__banner-heigth'
                textImg='blog__banner-text'
                bannerImage='/images/news.jpg'/>
                <h2 className='blog__all'>Toate articolele</h2>
                <div className='blog__wrapper'>
                    <div className='blog__container'>
                    {this.state.articles.map(article=>(
                        <div key={article.id} className='blog__card'>
                            {article.img? <img src={article.img} className='blog__poster' alt='invest imobiliare'/> :''}
                            <div className='blog__details'>
                            <h2 className='blog__title'>{article.title}</h2>
                            <h4 className='blog__date'>{article.date}</h4>
                            <p className='blog__text'>{article.text}</p>
                            </div>
                            <button>Citeste mai mult</button>
                        </div>
                    ))}
                    </div>
                    
                </div>
                <Footer/>
            </div> 
        )
    }
}

export default Blog;