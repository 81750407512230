import React from 'react';
import './Agent.scss';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer';
import MailModal from '../../components/MailModal/MailModal';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';

class Agent extends React.Component{
    constructor(props){
        super(props)
        this.state={
            isMailModalOpen:false,
            isFeedbackModalOpen:false
        }
    }
    openMailModal=()=>{
        this.setState({isMailModalOpen:true})
    }
    openFeedbackModal=()=>{
        this.setState({isFeedbackModalOpen:true})
    }
    closeModal=()=>{
        this.setState({isMailModalOpen:false})
        this.setState({isFeedbackModalOpen:false})
    }
    render(){
        return(
            <div className='agent'>
                <Header/>
                <div className='agent__wrapper'>
                    <div className='agent__box'>
                        <div className='agent__images'>
                            <img src='/images/victor.jpg' alt='agent' className='agent__main-img'/>
                            <img src='/images/InvestImobiliareLogo.png' alt='logo' className='agent__logo'/>
                        </div>
                        <div className='agent__right-side'>
                        <h2 className='agent__agent-name'>Victor Negru</h2>
                        <h3 className='agent__function'>Agent imobiliar la <span>Invest Imobiliare</span></h3>
                        <div className='agent__info-wrapper'>
                            <div className='agent__info'>
                                <img src='/images/email.svg' alt='contact icon'/>
                                    <h4 className='bold'>Email:</h4>
                                    <h4>Email@invest-imobiliare.com</h4>
                            </div>
                            <div className='agent__info'>
                                <img src='/images/phone.svg' alt='contact icon'/>
                                    <h4 className='bold'>Telefon:</h4>
                                    <h4>0747774547</h4>
                            </div>
                            <div className='agent__info'>
                                <img src='/images/location.svg' alt='contact icon'/>
                                    <h4 className='bold'>Adresa:</h4>
                                    <h4>Iasi</h4>
                            </div>
                            <div className='agent__info'>
                                <img src='/images/double-arrow.svg' alt='contact icon'/>
                                    <h4 className='bold'>Specialist:</h4>
                                    <h4>Management proprietati, Dezvoltare imobiliara</h4>
                            </div>
                        </div>
                        <div className='agent__buttons-box'>
                            <button className='agent__general-button' onClick={()=>this.openMailModal()}>
                                Trimite email
                            </button>
                            <button className='agent__call-button'>
                            Telefon
                            </button>
                            <button className='agent__whatsapp-button'>
                                <img src='/images/whatsapp.png' alt='whatsapp'/>
                                <h5>Whatsapp</h5>
                            </button>
                            <button className='agent__general-button'onClick={()=>this.openFeedbackModal()}>
                                Feedback agent
                            </button>
                        </div>
                        </div>
                    </div>

                    <div className='agent__box agent__description-box'>
                        <h2 className='agent__agent-name'>Despre Victor Negru</h2>
                        <p className='agent__self-description'>Certificatul de cazier judiciar se eliberează, de regulă, pe loc, sau în cel mult 3 zile de la data solicitării, în cazurile în care cererea-tip este depusă în România.</p>
                    </div>
                </div>
                { this.state.isMailModalOpen && <MailModal
                    modalIsOpen={this.state.isMailModalOpen}
                    closeModal={this.closeModal}
              /> }
              { this.state.isFeedbackModalOpen && <FeedbackModal
                    modalIsOpen={this.state.isFeedbackModalOpen}
                    closeModal={this.closeModal}
              /> }
                <Footer/>
            </div>
        )
    }
}

export default Agent;
