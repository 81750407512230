import React from 'react';
import './RequestForm.scss';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import { ClientOfferService } from '../../services/ClientOfferService';
import AttachementField from '../AttachementField/AttachementField';

class RequestForm extends React.Component{
    constructor(props){
        super(props);

        this.state={
            newRequest:{
                fullName:"",
                email:"",
                phone:"",
                propertyType:"",
                offerType: this.props.type,
                adress:"",
                city:"",
                descripton: "",
                agree:false,
                client_image_data: []
            },
            disabledDocument:false,
            sendRequest: false
        }
        this.handleChange=this.handleChange.bind(this);
        this.handleCheckBox=this.handleCheckBox.bind(this);
    }

    componentDidMount() {
      this._notificationSystem = this.refs.notificationSystem;
    }

    handleChange=event=>{
        let newRequest = { ...this.state.newRequest };
        newRequest[event.target.name] = event.target.value;

          this.setState({ newRequest });
      }
    handleCheckBox(){
        let newRequest={...this.state.newRequest}
        newRequest.agree=!newRequest.agree
        this.setState({
            newRequest
        })
    }

    handleClientOfferForm = event => {
      event.preventDefault();

      this.setState({ sendRequest: true });

      ClientOfferService
        .createOffer(this.state.newRequest)
        .then(response => {
          this._notificationSystem.addNotification({
            message: 'Am primit cererea dumneavoastra, vă vom contacta în cel mai scurt timp. Vă mulțumim.',
            level: 'success',
            position: 'tc'
          });

          this.setState({ sendRequest: false });

          this.resetForm();

        })
        .catch(error => {
          this._notificationSystem.addNotification({
            message: 'A apărut o eroare momentan pe server, vă rugăm să ne contactați la numărul de telefon afișat pentru mai multe informații.',
            level: 'error',
            position: 'tc'
          });

          this.setState({ sendRequest: false });
        });
    }

    resetForm = () => {
      const newRequest = {
        fullName:"",
        email:"",
        phone:"",
        propertyType:"",
        offerType: this.props.type,
        adress:"",
        city:"",
        descripton: "",
        agree:false,
        client_image_data: []
      }

      this.setState({ newRequest });
    }

    onDropDocuments = documents => {
      console.log(documents);
      let newRequest = { ...this.state.newRequest };
      let reader = new FileReader();
      let self = this;

      reader.onload = function(upload) {
        for (
          let documentIndex = 0;
          documentIndex < documents.length;
          documentIndex++
        ) {
          newRequest.client_image_data.push(documents[documentIndex]);
        }

        self.setState({ newRequest });
      };

      reader.readAsDataURL(documents[0]);
    };

      removeDocument = () => {
        let newRequest = { ...this.state.newRequest };
        newRequest.document = null;

        this.setState({ newRequest });
        this.setState({ disabledDocument: false });
      };


    render(){
        return(
            <div className="request-form">
            <h6 className='welcome-subtitle info-request'>Datele de contact</h6>
            <form className='request-form-whole' onSubmit={this.handleClientOfferForm}>
                <div className='request-form-part-1'>
                    <h4 className='request-label'>Nume si prenume</h4>
                    <input id='request-field'
                            required
                            type="text"
                            placeholder="Tasteaza numele și prenumele"
                            name="fullName"
                            value={this.state.newRequest.fullName}
                            onChange={this.handleChange}/>
                    <h4 className='request-label'>Email*</h4>
                    <input  id='request-field'
                            required
                            type="email"
                            placeholder="Tasteaza adresa ta de email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}/>
                    <h4 className='request-label'>Numar de telefon*</h4>
                    <input  id='request-field'
                            required
                            type="number"
                            placeholder="Tasteaza număr de telefon"
                            name="phone"
                            value={this.state.newRequest.phone}
                            onChange={this.handleChange}/>
                </div>
                <div className='request-form-part'>
                <h6 className='welcome-subtitle info-request info-request-second'>Detaliile Proprietatii</h6>
                <div className='type-city'>
                <h4 className='request-label'>Tipul proprietatii*</h4>
                <div className='request-dropdown'>
                    <FormControl className="search-button"  >
                            <NativeSelect
                                value={this.state.newRequest.propertyType}
                                name="propertyType"
                                type="text"
                                onChange={this.handleChange}
                                placeholder="Tip"
                            >
                                 <option value="">Tip</option>
                                <option value="Casa">Casă</option>
                                <option value="Apartament">Apartament</option>
                            </NativeSelect>
                    </FormControl>
                    </div>
                    <div className='request-label-2'>
                    <h4 className='request-label'>Oras*</h4>
                        <input  id='request-field'
                                required
                                type="text"
                                placeholder="Oraș"
                                name="city"
                                value={this.state.newRequest.city}
                                onChange={this.handleChange}/>
                    </div>
                </div>
                <h4 className='request-label'>Adresa proprietatii*</h4>
                    <input  id='adress-label'
                            required
                            type="text"
                            placeholder="Adresă"
                            name="adress"
                            value={this.state.newRequest.adress}
                            onChange={this.handleChange}/>
                <h4 className='request-label'>Descriere proprietate*</h4>
                    <textarea id='request-field textarea'
                              required
                              cols="30" rows="10"
                              placeholder='Descriere proprietate'
                              name="descripton"
                              value={this.state.newRequest.descripton}
                              onChange={this.handleChange}
                              />
                <div className='admin__attachement'>
                <h4 className='request-label'>Fotografii proprietate (optional):</h4>
                                <AttachementField
                                  onDrop={this.onDropDocuments}
                                  removeDocument={this.removeDocument}
                                  documents={this.state.newRequest.client_image_data}
                                  type="image/*"
                                />
                            </div>
                <div className='consent'>
                    <label >
                        <input id='request-field request-checkbox'
                            required
                            type="checkbox"
                            name="agree"
                            checked={this.state.newRequest.agree}
                            onChange={this.handleCheckBox} />
                    </label>
                    <h5 className='data-consent'>Sunt de acord cu prelucrarea datelor.</h5>
                </div>
                </div>
                <button className='general-button send-message request-button' disabled={this.state.sendRequest}>TRIMITE MESAJ</button>
            </form>
            <NotificationSystem
              ref="notificationSystem"
              style={NotificationSystemStyle}
            />
            </div>
        )
    }
}

export default RequestForm;
