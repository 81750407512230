import React, { Component } from 'react';
import './HamburgerMenu.scss';
import menuJson from '../menu.json';
import HamburgerLink from '../HamburgerLink/HamburgerLink';
import { Link} from 'react-router-dom';

class HamburgerMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenu: false,
      menu: menuJson,
    };
  }

  openMenu = () => {
    this.setState({ activeMenu: true });
    document.body.classList.add('has-active-menu');
  };

  closeMenu = () => {
    this.setState({ activeMenu: false });
    document.body.classList.remove('has-active-menu');
  };
  render() {
    return (
      <div className="hamburger">
        <span>
          <img
            onClick={this.openMenu}
            className="hamburger__icon"
            src="/images/menu.png"
            alt="hamburgetMenuLogo"
          />
        </span>
        <ul
          className={
            'hamburger__links' + (this.state.activeMenu ? ' active' : '')
          }
        >
        <img src='/images/investImobiliareLogo.png' alt='logo' className='hamburger__logo'/>
          {this.state.menu.map(listItem => (
            <HamburgerLink
              key={listItem.id}
              closeMenu={this.closeMenu}
              {...listItem}
            />
          ))}
          <Link to='/oferta-ta' className='standard-link'> <button className='hamburger__list-button'><h5>Listează Proprietate</h5></button></Link>
        </ul>
        <span>
          <img
            onClick={this.closeMenu}
            className={
              'hamburger__close-icon' + (this.state.activeMenu ? ' active' : '')
            }
            src="/images/close-gray.png"
            alt="close Icon"
          />
        </span>
      </div>
    );
  }
}

export default HamburgerMenu;
