import React from 'react';
import './Banner.scss';
import { withRouter} from 'react-router-dom';


class Banner extends React.Component{

  goToLink = path => {
    this.props.history.push(path);
  }

  render(){
    return(
      <div className="banner">
        <img src={this.props.bannerImage} alt={this.props.bannerImage} className="banner-img"/>
        <div className="container-introduction">
          <div className="introduction-text">
            <h1 className="motto">Gândim In Viitor</h1>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Banner);
