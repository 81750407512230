import React from 'react';
import './GridFilter.scss';
import searchZone from '../Search/searchZone.json';
import { withRouter} from 'react-router-dom';

class GridFilter extends React.Component{
    constructor(props){
      super(props);

      this.state={
        openFilterArea:false,
        openDesktopFilterArea:false,
        searchInfo:{
          propertyName:'',
          devanzare: false,
          deinchiriat: false,
          tranzaction: '',
          zona: [],
          tip:[],
          nrcamere:[],
          nrbai:[],
          idOferta:'',
          minimSurface:'',
          maximSurface:'',
          pretMinvanzare:'',
          pretMaxvanzare:'',
          pretMininchiriere: '',
          pretMaxinchiriere: ''
        }
      }
    }

    componentDidMount() {
      window.$('.selectpicker').selectpicker();

      if (this.props.searchQuery) {
        console.log("WTF");
        console.log(this.props.searchQuery);
        let searchInfo = { ...this.state.searchInfo };

        Object.keys(this.props.searchQuery).forEach(searchKey => {
          if (searchKey === 'devanzare') {
            searchInfo.devanzare = this.props.searchQuery[searchKey];
            searchInfo.tranzaction = 'vanzare';

            return;
          }

          if (searchKey === 'deinchiriat') {
            searchInfo.deinchiriat = this.props.searchQuery[searchKey];
            searchInfo.tranzaction = 'inchiriere';

            return;
          }

          if (['zona', 'tip', 'nrcamere', 'nrbai'].indexOf(searchKey) !== -1) {
            if (typeof this.props.searchQuery[searchKey] === 'string') {
              searchInfo[searchKey].push(this.props.searchQuery[searchKey]);
            } else {
              searchInfo[searchKey] = this.props.searchQuery[searchKey].slice();
            }

            return;
          }

          searchInfo[searchKey] = this.props.searchQuery[searchKey];
        });

        this.setState({ searchInfo });
      }
    }

    toggleFilterArea=()=>{
        this.setState({ openFilterArea: true });
    }
    toggleDesktopFilterArea=()=>{
        let openDesktopFilterArea=this.state.openDesktopFilterArea;
        openDesktopFilterArea=!openDesktopFilterArea;

        console.log(this.state.searchInfo);

        this.setState({openDesktopFilterArea}, () => {
          window.$('.selectpicker-zona').selectpicker('refresh');
          window.$('.selectpicker-camere').selectpicker('refresh');
          window.$('.selectpicker-bai').selectpicker('refresh');
        })
    }
    closeFilterArea=()=>{
        this.setState({openFilterArea:false});
    }
    handleChange = event => {
      const searchInfo = { ...this.state.searchInfo };
      searchInfo[event.target.name] = event.target.value;

      this.setState({ searchInfo });
    }

    handleTranzaction = event => {
      let searchInfo = { ...this.state.searchInfo };

      if (event.target.value === 'vanzare') {
        searchInfo.devanzare = true;
        searchInfo.deinchiriat = false;
        searchInfo.pretMininchiriere = '';
        searchInfo.pretMaxinchiriere = '';
      }

      if (event.target.value === 'inchiriere') {
        searchInfo.devanzare = false;
        searchInfo.deinchiriat = true;
        searchInfo.pretMinvanzare = '';
        searchInfo.pretMaxvanzare = '';
      }

      searchInfo.tranzaction = event.target.value;

      this.setState({ searchInfo });
    }

    handleArrayChange = (event, selectedOptions) => {
      let searchInfo = { ...this.state.searchInfo };

      searchInfo[event.target.name] = []

      for (let i=0; i<selectedOptions.length; i++) {
          searchInfo[event.target.name].push(selectedOptions[i].value);
      }

      this.setState({ searchInfo });
    }

    searchProperties = event => {
      event.preventDefault();

      let searchParams = `?currentPage=1${this.state.searchInfo.idOferta && `&idOferta=${this.state.searchInfo.idOferta}`}${this.state.searchInfo.propertyName && `&propertyName=${this.state.searchInfo.propertyName}`}${this.state.searchInfo.minimSurface && `&minimSurface=${this.state.searchInfo.minimSurface}`}${this.state.searchInfo.maximSurface && `&maximSurface=${this.state.searchInfo.maximSurface}`}${this.state.searchInfo.pretMinvanzare && `&pretMinvanzare=${this.state.searchInfo.pretMinvanzare}`}${this.state.searchInfo.pretMaxvanzare && `&pretMaxvanzare=${this.state.searchInfo.pretMaxvanzare}`}`;

      if (this.state.searchInfo.devanzare === true) {
        searchParams += `&devanzare=${this.state.searchInfo.devanzare}`;
      }

      if (this.state.searchInfo.deinchiriat === true) {
        searchParams += `&deinchiriat=${this.state.searchInfo.deinchiriat}`;
      }

      if (this.state.searchInfo.tip.length) {
        this.state.searchInfo.tip.map(tipProperty => {
          searchParams += `&tip=${tipProperty}`
        })
      }

      if (this.state.searchInfo.nrcamere.length) {
        this.state.searchInfo.nrcamere.map(nrCamere => {
          searchParams += `&nrcamere=${nrCamere}`
        })
      }

      if (this.state.searchInfo.zona.length) {
        this.state.searchInfo.zona.map(area => {
          searchParams += `&zona=${area}`
        })
      }

      if (this.state.searchInfo.nrbai.length) {
        this.state.searchInfo.nrbai.map(nrbai => {
          searchParams += `&nrbai=${nrbai}`
        })
      }

      this.props.history.push({
        pathname: 'proprietati',
        search: searchParams
      });

      this.closeFilterArea()
    }

    resetFilters = event => {
      let searchInfo = { ...this.state.searchInfo };

      searchInfo = {
        propertyName:'',
        devanzare: false,
        deinchiriat: false,
        tranzaction: '',
        zona: [],
        tip:[],
        nrcamere:[],
        nrbai:[],
        idOferta:'',
        minimSurface:'',
        maximSurface:'',
        pretMinvanzare:'',
        pretMaxvanzare:'',
        pretMininchiriere: '',
        pretMaxinchiriere: ''
      };

      this.setState({ searchInfo }, () => {
        window.$('.selectpicker-tip').selectpicker('refresh');
        window.$('.selectpicker-tranzaction').selectpicker('refresh');
        window.$('.selectpicker-zona').selectpicker('refresh');
        window.$('.selectpicker-camere').selectpicker('refresh');
        window.$('.selectpicker-bai').selectpicker('refresh');
      });
    }

    render(){
        return(
            <div className='filter'>
                <div className='filter__only-mobile'>
                <div className='filter__top'>
                    <button className='filter__search-button' onClick={()=>this.toggleFilterArea()}>
                        <img src='/images/search.svg' alt='search'/>
                        <h5>Cauta proprietate...</h5>
                    </button>
                   <div className={'filter__wrapper '+ (this.state.openFilterArea? 'active':'')}>
                    <div className='filter__top-box'>
                        <h2>Cautare</h2>
                        <img src='/images/close-white.png' alt='close' onClick={()=>this.closeFilterArea()}/>
                    </div>
                   <form className='filter__form' onSubmit={this.searchProperties}>
                        <div className='filter__search-box'>
                        <input
                            className='filter__search-input'
                            type="text"
                            name="propertyName"
                            onChange={this.handleChange}
                            value={this.state.searchInfo.propertyName}
                            placeholder="Caută proprietate..."/>
                        </div>
                        <div className='filter__dropdown-container'>
                            <div className="filter__dropdown">
                              <select
                                className="selectpicker selectpicker-tranzaction show-tick form-control"
                                data-header="Tip Tranzactie"
                                title="Tip Tranzactie"
                                value={this.state.searchInfo.tranzaction}
                                name="tranzaction"
                                onChange={this.handleTranzaction}
                                >
                                  <option value="vanzare">Vanzare</option>
                                  <option value="inchiriere">Inchiriere</option>
                              </select>
                            </div>
                            <div className="filter__dropdown">
                              <select
                                className="dropdown selectpicker selectpicker-tip show-tick form-control"
                                multiple
                                data-header="Tip Proprietate"
                                title="Tip Proprietate"
                                value={this.state.searchInfo.tip}
                                name="tip"
                                onChange={event => this.handleArrayChange(event, event.target.selectedOptions)}
                                >
                                  <option value="garsoniera">Garsonieră</option>
                                  <option value="casa / vila">Casă/Vilă</option>
                                  <option value="teren">Teren</option>
                                  <option value="apartament">Apartament</option>
                                  <option value="spatiubirou">Spațiu Birou</option>
                                  <option value="spatiucomercial">Spațiu Comercial</option>
                                  <option value="spatiuindustrial">Spațiu Industrial</option>
                                </select>
                            </div>
                            <div className="filter__dropdown">
                              <select
                                className="dropdown selectpicker selectpicker-zona form-control"
                                multiple
                                data-header="Alege zona"
                                data-live-search
                                data-selected-text-format="count"
                                data-count-selected-text="{0} din {1} selectat(e)"
                                title="Alege zona"
                                value={this.state.searchInfo.zona}
                                name="zona"
                                onChange={event => this.handleArrayChange(event, event.target.selectedOptions)}
                              >
                                {searchZone && searchZone.length && searchZone.map(zone => (
                                  <option key={zone.id} value={zone.value}>{zone.name}</option>
                                ))}
                              </select>
                            </div>
                            <div className='filter__dropdown'>
                              <input
                                className="form-control id-oferta-input filter__oferta-input"
                                placeholder="ID Oferta"
                                name="idOferta"
                                value={this.state.searchInfo.idOferta}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="filter__dropdown">
                              <select
                                className="dropdown selectpicker selectpicker-camere show-tick form-control"
                                multiple
                                data-header="Nr. camere"
                                title="Nr. Camere"
                                value={this.state.searchInfo.nrcamere}
                                onChange={event => this.handleArrayChange(event, event.target.selectedOptions)}
                                name="nrcamere"
                                >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">7+</option>
                              </select>
                            </div>
                            <div className="filter__dropdown">
                              <select
                                className="dropdown selectpicker selectpicker-bai show-tick form-control"
                                multiple
                                data-header="Nr. bai"
                                title="Nr. bai"
                                value={this.state.searchInfo.nrbai}
                                onChange={event => this.handleArrayChange(event, event.target.selectedOptions)}
                                name="nrbai"
                                >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">4+</option>
                              </select>
                            </div>
                            <div className='filter__dropdown'>
                              <input
                                name="minimSurface"
                                type="number"
                                className="form-control id-oferta-input filter__oferta-input"
                                placeholder="Suprafață minim"
                                value={this.state.searchInfo.minimSurface}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className='filter__dropdown'>
                              <input
                                name="maximSurface"
                                type="number"
                                className="form-control id-oferta-input filter__oferta-input"
                                placeholder="Suprafață maximă"
                                value={this.state.searchInfo.maximSurface}
                                onChange={this.handleChange}
                              />
                            </div>
                            { this.state.searchInfo.devanzare && (
                              <div className='filter__dropdown'>
                                <input
                                  name="pretMinvanzare"
                                  type="number"
                                  className="form-control id-oferta-input filter__oferta-input"
                                  placeholder="Preț minim vanzare"
                                  value={this.state.searchInfo.pretMinvanzare}
                                  onChange={this.handleChange}
                                />
                              </div>
                            ) }
                            { this.state.searchInfo.devanzare && (
                              <div className='filter__dropdown'>
                                <input
                                  name="pretMaxvanzare"
                                  type="number"
                                  className="form-control id-oferta-input filter__oferta-input"
                                  placeholder="Preț maxim vanzare"
                                  value={this.state.searchInfo.pretMaxvanzare}
                                  onChange={this.handleChange}
                                />
                              </div>
                            ) }
                            { this.state.searchInfo.deinchiriat && (
                              <div className='filter__dropdown'>
                                <input
                                  name="pretMinvanzare"
                                  type="number"
                                  className="form-control id-oferta-input filter__oferta-input"
                                  placeholder="Preț minim inchiriere"
                                  value={this.state.searchInfo.pretMinvanzare}
                                  onChange={this.handleChange}
                                />
                              </div>
                            ) }
                            { this.state.searchInfo.deinchiriat && (
                              <div className='filter__dropdown'>
                                <input
                                  name="pretMaxvanzare"
                                  type="number"
                                  className="form-control id-oferta-input filter__oferta-input"
                                  placeholder="Preț maxim inchiriere"
                                  value={this.state.searchInfo.pretMaxvanzare}
                                  onChange={this.handleChange}
                                />
                              </div>
                            ) }
                        </div>
                        <button className='filter__submit-button'>Caută</button>
                        <button type="button" className='filter__submit-button' onClick={event => this.resetFilters(event)}>Sterge Filtre</button>
                    </form>
                   </div>
                </div>
                </div>

                <div className='filter__only-desktop'>
                <form className='filter__form' onSubmit={this.searchProperties}>
                    <div className='filter__form-top'>
                        <div className='filter__search-box'>
                          <input
                              className='filter__search-input'
                              type="text"
                              name="propertyName"
                              onChange={this.handleChange}
                              value={this.state.searchInfo.propertyName}
                              placeholder="Caută proprietate..."/>

                        </div>

                        <div className="filter__dropdown">
                          <select
                            className="selectpicker selectpicker-tranzaction show-tick form-control"
                            data-header="Tip Tranzactie"
                            title="Tip Tranzactie"
                            value={this.state.searchInfo.tranzaction}
                            name="tranzaction"
                            onChange={this.handleTranzaction}
                            >
                              <option value="vanzare">Vanzare</option>
                              <option value="inchiriere">Inchiriere</option>
                          </select>
                        </div>
                        <div className="filter__dropdown">
                          <select
                            className="dropdown selectpicker selectpicker-tip show-tick form-control"
                            multiple
                            data-header="Tip Proprietate"
                            title="Tip Proprietate"
                            value={this.state.searchInfo.tip}
                            name="tip"
                            onChange={event => this.handleArrayChange(event, event.target.selectedOptions)}
                            >
                              <option value="garsoniera">Garsonieră</option>
                              <option value="casa / vila">Casă/Vilă</option>
                              <option value="teren">Teren</option>
                              <option value="apartament">Apartament</option>
                              <option value="spatiubirou">Spațiu Birou</option>
                              <option value="spatiucomercial">Spațiu Comercial</option>
                              <option value="spatiuindustrial">Spațiu Industrial</option>
                            </select>
                        </div>

                        <div className='filter__options' onClick={()=>this.toggleDesktopFilterArea()}>
                            <img src='/images/settings.png' alt='settings'/>
                            <h4>Opțiuni</h4>
                        </div>
                        <button className='filter__submit-button'>Caută</button>
                        </div>


                            {this.state.openDesktopFilterArea ?
                              <div className='filter__form-bottom'>
                                <div className="filter__dropdown filter__bottom-input">
                                  <select
                                    className="dropdown selectpicker selectpicker-zona form-control"
                                    multiple
                                    data-header="Alege zona"
                                    data-live-search
                                    data-selected-text-format="count"
                                    data-count-selected-text="{0} din {1} selectat(e)"
                                    title="Alege zona"
                                    value={this.state.searchInfo.zona}
                                    name="zona"
                                    onChange={event => this.handleArrayChange(event, event.target.selectedOptions)}
                                  >
                                    {searchZone && searchZone.length && searchZone.map(zone => (
                                      <option key={zone.id} value={zone.value}>{zone.name}</option>
                                    ))}
                                  </select>
                                </div>
                                <div className="filter__dropdown filter__bottom-input" >
                                  <select
                                    className="dropdown selectpicker selectpicker-camere show-tick form-control"
                                    multiple
                                    data-header="Nr. camere"
                                    title="Nr. Camere"
                                    value={this.state.searchInfo.nrcamere}
                                    onChange={event => this.handleArrayChange(event, event.target.selectedOptions)}
                                    name="nrcamere"
                                    >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">7+</option>
                                  </select>
                                </div>
                                <div className="filter__dropdown filter__bottom-input" >
                                  <select
                                    className="dropdown selectpicker selectpicker-bai show-tick form-control"
                                    multiple
                                    data-header="Nr. bai"
                                    title="Nr. bai"
                                    value={this.state.searchInfo.nrbai}
                                    onChange={event => this.handleArrayChange(event, event.target.selectedOptions)}
                                    name="nrbai"
                                    >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">4+</option>
                                  </select>
                                </div>
                                <div className='filter__dropdown filter__bottom-input'>
                                  <input
                                    className="form-control id-oferta-input filter__oferta-input"
                                    placeholder="ID Oferta"
                                    name="idOferta"
                                    value={this.state.searchInfo.idOferta}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className='filter__dropdown filter__bottom-input'>
                                  <input
                                    name="minimSurface"
                                    type="number"
                                    className="form-control id-oferta-input filter__oferta-input"
                                    placeholder="Suprafață minim"
                                    value={this.state.searchInfo.minimSurface}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className='filter__dropdown filter__bottom-input'>
                                  <input
                                    name="maximSurface"
                                    type="number"
                                    className="form-control id-oferta-input filter__oferta-input"
                                    placeholder="Suprafață maximă"
                                    value={this.state.searchInfo.maximSurface}
                                    onChange={this.handleChange}
                                  />
                                </div>

                                { this.state.searchInfo.devanzare && (
                                  <div className='filter__dropdown filter__bottom-input'>
                                    <input
                                      name="pretMinvanzare"
                                      type="number"
                                      className="form-control id-oferta-input filter__oferta-input"
                                      placeholder="Preț minim vanzare"
                                      value={this.state.searchInfo.pretMinvanzare}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                ) }
                                { this.state.searchInfo.devanzare && (
                                  <div className='filter__dropdown filter__bottom-input'>
                                    <input
                                      name="pretMaxvanzare"
                                      type="number"
                                      className="form-control id-oferta-input filter__oferta-input"
                                      placeholder="Preț maxim vanzare"
                                      value={this.state.searchInfo.pretMaxvanzare}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                ) }
                                { this.state.searchInfo.deinchiriat && (
                                  <div className='filter__dropdown filter__bottom-input'>
                                    <input
                                      name="pretMinvanzare"
                                      type="number"
                                      className="form-control id-oferta-input filter__oferta-input"
                                      placeholder="Preț minim inchiriere"
                                      value={this.state.searchInfo.pretMinvanzare}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                ) }
                                { this.state.searchInfo.deinchiriat && (
                                  <div className='filter__dropdown filter__bottom-input'>
                                    <input
                                      name="pretMaxvanzare"
                                      type="number"
                                      className="form-control id-oferta-input filter__oferta-input"
                                      placeholder="Preț maxim inchiriere"
                                      value={this.state.searchInfo.pretMaxvanzare}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                ) }

                        </div> :''}
                    </form>
                </div>
            </div>
        )
    }
}

export default withRouter(GridFilter);
