import React from 'react';
import Modal from 'react-modal';
import '../MailModal/MailModal.scss';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';

const customStyles = {
    overlay: {
      zIndex: 12,
      backgroundColor: 'rgb(245, 245, 245, 0.9)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      height: '90%',
    }
  };

class MailModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            feedbackInfo: {
                name: '',
                email: '',
                phone: '',
                user:'',
                feedback: '',
                agree:''
              }
        };

        this.notificationSystem = React.createRef();
      }


      updateField = event => {
        const field = event.target.name;
        const mailInfo = { ...this.state.mailInfo };
        mailInfo[field] = event.target.value;
        this.setState({
            mailInfo
        });
      };


    render(){
        return(
            <div>
                <Modal
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.props.closeModal}
                    style={customStyles}
                    contentLabel="Providers Hours"
                    ariaHideApp={false}
                >
            <div className='standard modal'>
            <img src='/images/close-gray.png' className='modal__close-icon modal__feedback-close' alt='close' onClick={this.props.closeModal}/>
            <div className='modal__top modal__feedback-top'>
                <img src='/images/victor.jpg' alt='agent' className='modal__agent-photo modal__feedback-agent-photo'/>
                <div className='modal__top-info'>
                    <div className='modal__feedback-info'>
                    <h4><b>Parerea ta sincera ne ajuta sa ne imbunatatim continuu.</b> </h4>
                    <h4>Trimite-ne un feedback despre <b>Mike Moore</b>.</h4>
                    </div>
                </div>
            </div>

            <form className="add-problem__form" >
                      <input
                        required
                        className="standard__field"
                        type="text"
                        name="name"
                        onChange={this.updateField}
                        value={this.state.feedbackInfo.name}
                        placeholder="Nume *"
                      />
                      <input
                        required
                        className="standard__field"
                        type="email"
                        name="email"
                        onChange={this.updateField}
                        value={this.state.feedbackInfo.email}
                        placeholder="Email*"
                      />
                       <input
                        required
                        className="standard__field"
                        type="number"
                        name="phone"
                        onChange={this.updateField}
                        value={this.state.feedbackInfo.phone}
                        placeholder="Numar de telefon*"
                      />
                      <div className='modal__dropdown'>
                       <FormControl className="search-button"
                       >
                       <NativeSelect
                                  value={this.state.feedbackInfo.user}
                                  onChange={this.handleChange}
                                  name="user"
                                  disableUnderline={true}
                              >
                                  <option value="">Sunt:</option>
                                  <option value="cumparator">Cumparator</option>
                                  <option value="vanzator">Vanzator</option>
                                  <option value="agent">Agent</option>
                                  <option value="teren">Teren</option>
                              </NativeSelect>
                          </FormControl>
                        </div>
                      <textarea
                        required
                        rows="8"
                        className="modal__textarea"
                        type="text"
                        name="feedback"
                        onChange={this.updateField}
                        value={this.state.feedbackInfo.feedback}
                        placeholder="Feedback-ul dumneavoastra*"
                      />
                       <div className='consent'>
                    <label >
                        <input id='request-field request-checkbox'
                            required
                            type="checkbox"
                            name="agree"
                            checked={this.state.feedbackInfo.agree}
                            onChange={this.handleCheckBox} />
                    </label>
                    <h5 className='data-consent modal__data-consent'>Sunt de acord cu prelucrarea datelor.</h5>
                </div>
                      <div className='add-problem__submit-buttons'>
                      <button
                        type="submit"
                        className="modal__submit-button"
                      >
                         Trimite mail
                      </button>
                      </div>
                    </form>
            </div>
      </Modal>
            </div>
        )
    }
}

export default MailModal;
