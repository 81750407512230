import React from 'react';
import './Search.scss';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from 'react-router-dom';
import searchZone from './searchZone.json';

const styles = {}

class Search extends React.Component{
    constructor(props){
        super(props);

        this.state={
            zona: [],
            devanzare: true,
            deinchiriat: false,
            nrcamere: [],
            tip: [],
            idOferta: ''
        }
    }

    componentDidMount() {
      window.$('.selectpicker').selectpicker();
    }

    handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;

      this.setState({ [name]: value });
    }

    handleTipChange = (event, selectedOptions) => {
      let tip = this.state.tip.slice();

      tip = [];

      for (let i=0; i<selectedOptions.length; i++) {
          tip.push(selectedOptions[i].value);
      }

      this.setState({ tip });
    }

    handleZonaChange = (event, selectedOptions) => {
      let zona = this.state.zona.slice();

      zona = [];

      for (let i=0; i<selectedOptions.length; i++) {
          zona.push(selectedOptions[i].value);
      }

      this.setState({ zona });
    }

    handleNrCamereChange = (event, selectedOptions) => {
      let nrcamere = this.state.nrcamere.slice();

      nrcamere = [];

      for (let i=0; i<selectedOptions.length; i++) {
          nrcamere.push(selectedOptions[i].value);
      }

      this.setState({ nrcamere });
    }

    searchProperties = event => {
      event.preventDefault();

      let searchParams = `?${this.state.devanzare === true ? `devanzare=${this.state.devanzare}` : `deinchiriat=${this.state.deinchiriat}`}${this.state.idOferta && `&idOferta=${this.state.idOferta}`}`;

      if (this.state.tip.length) {
        this.state.tip.map(tipProperty => {
          searchParams += `&tip=${tipProperty}`
        })
      }

      if (this.state.nrcamere.length) {
        this.state.nrcamere.map(nrCamere => {
          searchParams += `&nrcamere=${nrCamere}`
        })
      }

      if (this.state.zona.length) {
        this.state.zona.map(area => {
          searchParams += `&zona=${area}`
        })
      }

      this.props.history.push({
        pathname: 'proprietati',
        search: searchParams
      });
    }

    setSellingType = (type) => {
      if (type === 'devanzare') {
        this.setState({ devanzare: true });
        this.setState({ deinchiriat: false });
      }

      if (type === 'deinchiriat') {
        this.setState({ devanzare: false });
        this.setState({ deinchiriat: true });
      }
    };

    render(){
        return(

            <div className="search-area">
              <div className='tabs-wrapper'>
                <div className='tabs'>
                  <div className="tab-top-container" onClick={() => this.setSellingType('devanzare')}>
                    <h5 className={this.state.devanzare ? "tab-top-value active" : "tab-top-value" }>Vânzari</h5>
                  </div>
                  <div className="tab-top-container" onClick={() => this.setSellingType('deinchiriat')}>
                    <h5 className={this.state.deinchiriat ? "tab-top-value active" : "tab-top-value" }>Închirieri</h5>
                  </div>
                </div>
               <div className="tab-wrapper">
                   <form onSubmit={this.searchProperties} className='search-form'>
                    <div className="form-group">
                        <div className="search-area__dropdown">
                          <h4 className='label label-search'>CAUȚI</h4>
                          <select
                            className="dropdown selectpicker show-tick form-control"
                            multiple
                            data-header="TIP PROPRIETATE"
                            title="Tip Proprietate"
                            value={this.state.tip}
                            name="tip"
                            onChange={event => this.handleTipChange(event, event.target.selectedOptions)}
                            >
                            <option value="garsoniera">Garsonieră</option>
                            <option value="casa / vila">Casă/Vilă</option>
                            <option value="teren">Teren</option>
                            <option value="apartament">Apartament</option>
                            <option value="spatiubirou">Spațiu Birou</option>
                            <option value="spatiucomercial">Spațiu Comercial</option>
                            <option value="spatiuindustrial">Spațiu Industrial</option>
                          </select>
                        </div>
                        <div className="search-area__dropdown">
                        <h4 className='label label-search'>LOCAȚIE</h4>
                          <select
                            className="dropdown selectpicker form-control"
                            multiple
                            data-header="ALEGE ZONE"
                            data-live-search
                            data-selected-text-format="count"
                            data-count-selected-text="{0} din {1} selectat(e)"
                            title="Alege zone"
                            value={this.state.zona}
                            name="zona"
                            onChange={event => this.handleZonaChange(event, event.target.selectedOptions)}
                          >
                            {searchZone && searchZone.length && searchZone.map(zone => (
                              <option key={zone.id} value={zone.value}>{zone.name}</option>
                            ))}
                          </select>
                        </div>

                        <div className="search-area__dropdown">
                          <h4 className='label label-search'>NR CAMERE</h4>
                          <select
                            className="dropdown selectpicker show-tick form-control"
                            multiple
                            data-header="NR. CAMERE"
                            title="Nr. Camere"
                            value={this.state.nrcamere}
                            onChange={event => this.handleNrCamereChange(event, event.target.selectedOptions)}
                            name="nrcamere"
                            >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">7+</option>
                          </select>
                        </div>

                          <div className='search-area__dropdown'>
                            <h4 className='label label-search'>ID OFERTĂ</h4>
                            <input
                              className="form-control id-oferta-input"
                              placeholder="ID Oferta"
                              name="idOferta"
                              value={this.state.idOferta}
                              onChange={this.handleChange}
                            />
                          </div>
                          <button className="search-area-button">CAUTĂ LA {this.state.devanzare ? 'VÂNZARI' : 'ÎNCHIRIERI'}</button>

                    </div>
                </form>
                    </div>
            </div>
          </div>
        )
    }
}


export default withRouter(withStyles(styles)(Search));
