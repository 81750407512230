import React, { Component } from 'react';
import './Menu.scss';
import { Link} from 'react-router-dom';
import menuJson from '../menu.json';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: menuJson,
      openMenuById: 0
    };
  }

  enterButton = id => {
    this.setState({ openMenuById: id });
    document.body.classList.add('has-active-menu');
  };

  leaveButton = () => {
    this.setState({ openMenuById: 0 });
    document.body.classList.remove('has-active-menu');
  };



  render() {
    return (
      <ul className='menu'>
          {this.state.menu.map(menuLink => (
            <div key={menuLink.id} className={menuLink.img? 'menu__call-box': ''}>
            {menuLink.img? <a href={`tel:${menuLink.label}`}> <img src={menuLink.img} className='menu__icon' alt='call'/> </a>:'' }
            <li key={menuLink.id} className={menuLink.img? 'menu-item menu__call-item': 'menu-item'}>
              { menuLink.phoneLink ? (
                <a href={`tel:${menuLink.label}`}>{menuLink.label}</a>
              ) : (
                <Link to={menuLink.link} onClick={() => this.leaveButton()}>{menuLink.label}</Link>
              )}
            </li>
            </div>
          ))}
         <Link to='/oferta-ta' className='standard-link list-link'><button className='list-button'>Listează Proprietate</button></Link>
      </ul>
    );
  }
}

export default Menu;
