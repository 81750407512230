import React from 'react';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component{
    render(){
        return(
            <footer className='footer-homepage'>
                <div className='footer-info'>
                    <p className='contact-description-text'> <b>Agentia Invest Imobiliare</b> oferă servicii profesionale în domeniul imobiliar, iar satisfacția clienților noștri ne poziționează în topul agențiilor din județul Iași.</p>
                    <div className='all-info'>
                    <ul className="contact-info">
                    <FontAwesomeIcon icon={faMapMarker} className='contact-icon'/>
                        <li class-name='contact-detail'>Bulevardul Tutora, nr. 4, Bloc 4, scara D, ap.4 Iasi</li>
                        <FontAwesomeIcon icon={faPhone} className='contact-icon'/>
                        <li><a className='footer-link' href="tel:0752979059">0752979059</a> / <a className='footer-link' href="tel:0749455951">0749455951</a></li>
                        <FontAwesomeIcon icon={faEnvelope} className='contact-icon'/>
                        <li> <a  className='footer-link'href="mail:office@investimobiliare.ro">office@investimobiliare.ro</a></li>

                    </ul>
                    <div className='social-box'>
                    <FontAwesomeIcon icon={faFacebookF} className='contact-svg footer-icon'/>
                    <FontAwesomeIcon icon={faInstagram} className='contact-svg footer-icon'/>
                    <FontAwesomeIcon icon={faLinkedinIn} className='contact-svg footer-icon'/>
                    </div>
                    </div>
                </div>
                <p className='contact-description' id="copyright">© Designed and Maintained by <a href="www.prometeus-tech.com" target="_blank">Prometeus Tech</a> 2020</p>
            </footer>
        )
    }
}


export default Footer;
