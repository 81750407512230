import React from 'react';
import './Properties.scss';
import { withRouter} from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/ro';
import * as moment from 'moment';
import { Link } from 'react-router-dom';

class Properties extends React.Component{
  constructor(props){
    super(props)
    this.state={
      favoriteProperties:[]
    }
  }

  componentDidMount() {
    this.getFavoriteProperties();
  }

  addToFavorite=(event, addedProperty)=>{
    event.stopPropagation();
    event.preventDefault();

    let favoriteProperties = this.state.favoriteProperties.slice();
    const propertyIndex = favoriteProperties.findIndex(property => property.id === addedProperty.id);

    if (propertyIndex !== -1) {
      favoriteProperties.splice(propertyIndex, 1);
    } else {
      favoriteProperties.push(addedProperty);
    }

    this.setState({ favoriteProperties }, () => {
      window.favoriteEventEmiter.emitEvent('addNewFavoritesProperties', [addedProperty]);
    });
  }

  getFavoriteProperties() {
    let favoriteProperties=JSON.parse(localStorage.getItem('favoriteProperties'));

    if (!favoriteProperties){
        favoriteProperties=[];
      }
    this.setState({
        favoriteProperties
    });
  }

    render(){
      const currentDate = moment();
        return(
            <div className={"properties-container "+(this.props.title==='OFERTE PROMOVATE'? 'container-relative':'')}>
              {this.props.title && (
                <h2>{this.props.title}</h2>
              )}
              {this.props.gridStyle?
                  <div className='properties-wrapper'>
                  { this.props.properties.length ?
                    this.props.properties.map( property => (
                      <Link key={property.id} className='individual-property' to={`/proprietate/${property.idstr}`}>
                        <div className="property-wrapper">
                          <div className='img-property'>
                            {
                              property.images && property.images.length ? (
                                <div>
                                  <img className="property-main-image" src={`${process.env.REACT_APP_API_URL}/${property.images[0].image}`} alt="Property Main" />
                                  <div className="property-main-image-wrapper"></div>
                                </div>
                              ) : (
                                <div>
                                  <img className="property-main-image" src="/images/propertyexample.jpg" alt="Property Main" />
                                  <div className="property-main-image-wrapper"></div>
                                </div>
                              )}
                              <div>
                                <div className='tranzaction-type'>
                                    { property.devanzare && (
                                      <h4 className='tranzaction'>VÂNZARE</h4>
                                    )}
                                    { property.deinchiriat && (
                                      <h4 className='tranzaction'>ÎNCHIRIERE</h4>
                                    )}
                                </div>
                                { property.pole === '1' ? <div className='promoted'>PROMOVAT</div> : null }
                                { property.exclusivitate === '1' ? <div className='exclusivitate'>EXCLUSIVITATE</div> : null }
                              </div>
                            <div className='actions-box'>
                                <img src='/images/heart.png' alt='favourite' className={this.state.favoriteProperties.findIndex(favoriteProperty => favoriteProperty.id === property.id) !== -1 ? 'property-heart highlight':'property-heart'} onClick={(event)=>this.addToFavorite(event, property)}/>
                            </div>
                            <div className='price-share'>
                            {property.pretvanzare && <h3 className='price'>{property.pretvanzare} {property.monedavanzare}</h3>}
                            {property.pretinchiriere && <h3 className='price'>{property.pretinchiriere} {property.monedainchiriere}</h3>}
                        </div>
                            <div>
                              {property.devanzare || property.deinchiriat ? ('') : (
                                <div className='properties-unavailable'>
                                  <h4 className='properties-unavailable-writing'>NU MAI ESTE VALABIL</h4>
                                </div>
                              )}
                            </div>

                          </div>
                            <h4 className='property-title'>{property.titlu}</h4>
                            <div className='property-adress'>

                                <p>{property.zona || property.adresa}, {property.judet}</p>
                            </div>
                          <div className='property__details-wrapper'>
                            <div className='property-details'>
                                <div className='property-info'>
                                    <img src='/images/bed.png' alt='property' className='detail-icon'/>
                                    <p className='detail-item'>{property.nrcamere ? property.nrcamere : '-'}</p>
                                </div>

                                <div className='property-info'>
                                    <img src='/images/shower.png' alt='property' className='detail-icon'/>
                                    <p className='detail-item'>{property.nrbai ? property.nrbai : '-'}</p>
                                </div>

                                {/*<div className='property-info'>
                                    <p className='detail-item'>Id Ofertă</p>
                              <p className='detail-item'>{property.idstr}</p>
                                </div>*/}
                                <div className='property-info'>
                                  <img src='/images/surface.png' alt='property' className='detail-icon'/>
                                    <p className='detail-item'>{property.suprafataconstruita && property.suprafataconstruita.slice(0, -3)} mp</p>
                                </div>
                            </div>
                            <button className='only-tablet property__see-details'>Detalii</button>
                          </div>
                          <h4 className='property-type'>{property.tip === 'casavila' ? 'CASA/VILA' : property.tip.toUpperCase()}</h4>
                          <div className='property-extra-info'>
                              <div className='property-extra-box'>
                                <img src='/images/user.png' alt='user'/>
                                <h4>{property.agent.nume}</h4>
                              </div>
                              <div className='property-extra-box'>
                                <img src='/images/clock.png' alt='clock'/>
                                {
                                  currentDate.diff(moment(property.datamodificare), 'hours') < 24 ? (
                                    <h4>ultimele 24 ore</h4>
                                  ) : (
                                    <h4><Moment locale="ro" fromNow>{property.datamodificare}</Moment></h4>
                                  )}

                              </div>
                          </div>
                          </div>

                      </Link>
                    )) : (
                      <div className="properties-wrapper__no-search-result">Nu s-au găsit rezultate care să corespundă criteriilor dvs!</div>
                    )}
                </div> :
        <div className='properties-wrapper properties-grid-wrapper'>
        { this.props.properties.length ?
          this.props.properties.map( property => (
            <Link key={property.id} className='individual-property individual-grid-property' to={`/proprietate/${property.idstr}`}>
              <div className="property-wrapper property-grid-wrapper">
                <div className='img-property img-grid-property'>
                  {
                    property.images && property.images.length ? (
                      <div>
                        <img className="property-main-image" src={`${process.env.REACT_APP_API_URL}/${property.images[0].image}`} alt="Property Main" />
                        <div className="property-main-image-wrapper"></div>
                      </div>
                    ) : (
                      <div>
                        <img className="property-main-image" src="/images/propertyexample.jpg" alt="Property Main" />
                        <div className="property-main-image-wrapper"></div>
                      </div>
                    )}

                    <div>
                      <div className='tranzaction-type'>
                          { property.devanzare && (
                            <h4 className='tranzaction'>VÂNZARE</h4>
                          )}
                          { property.deinchiriat && (
                            <h4 className='tranzaction'>ÎNCHIRIERE</h4>
                          )}
                      </div>
                      { property.pole === '1' ? <div className='promoted'>PROMOVAT</div> : null }
                      { property.exclusivitate === '1' ? <div className='exclusivitate'>EXCLUSIVITATE</div> : null }
                    </div>
                  <div className='actions-box'>
                      <img src='/images/heart.png' alt='favourite' className={this.state.favoriteProperties.findIndex(favoriteProperty => favoriteProperty.id === property.id) !== -1 ? 'property-heart highlight':'property-heart'} onClick={(event)=>this.addToFavorite(event, property)}/>
                  </div>
                  <div>
                    {property.devanzare || property.deinchiriat ? ('') : (
                      <div className='properties-unavailable'>
                        <h4 className='properties-unavailable-writing'>NU MAI ESTE VALABIL</h4>
                      </div>
                    )}
                  </div>
                </div>
                    <div className='grid-details'>
                      <div className='grid-details-top'>
                        <div className='price-share-grid'>
                          {property.pretvanzare && <h3 className='price'>{property.pretvanzare} {property.monedavanzare}</h3>}
                          {property.pretinchiriere && <h3 className='price'>{property.pretinchiriere} {property.monedainchiriere}</h3>}
                       </div>
                      <div className='tranzaction-type tranzaction-grid-type'>
                        { property.devanzare && (
                          <h4 className='tranzaction'>VÂNZARE</h4>
                        )}
                        { property.deinchiriat && (
                          <h4 className='tranzaction'>ÎNCHIRIERE</h4>
                        )}
                    </div>
                    </div>
                  <h4 className='property-title property-grid-title'>{property.titlu}</h4>
                      <div className='property-adress'>

                          <p>{property.zona}, {property.judet}</p>
                      </div>

                <div className='property-details property-grid-details-info'>
                    <div className='property-info property-grid-detail'>
                        <img src='/images/bed.png' alt='property' className='detail-icon'/>
                        <p className='detail-item'>{property.nrcamere ? property.nrcamere : '-'}</p>
                    </div>

                    <div className='property-info property-grid-detail'>
                        <img src='/images/shower.png' alt='property' className='detail-icon'/>
                        <p className='detail-item'>{property.nrbai ? property.nrbai : '-'}</p>
                    </div>

                    <div className='property-info property-grid-detail'>
                      <img src='/images/surface.png' alt='property' className='detail-icon'/>
                        <p className='detail-item'>{property.suprafataconstruita && property.suprafataconstruita.slice(0, -3)} mp</p>
                    </div>
                    <div className='property-info property-grid-detail'>
                      <h4 className='property-type'>{property.tip.toUpperCase()}</h4>
                    </div>
                </div>
                <div className='grid-bottom'>
                  <div className='property-extra-info property-grid-extra-info'>
                      <div className='property-extra-box'>
                        <img src='/images/user.png' alt='user'/>
                        <h4>{property.agent.nume}</h4>
                      </div>
                      <div className='property-extra-box'>
                        <img src='/images/clock.png' alt='clock'/>
                        <h4> <Moment locale="ro" fromNow>{property.datamodificare}</Moment></h4>
                      </div>
                  </div>
                    <button className='only-desktop'>Detalii</button>
                </div>
                </div>
                </div>
            </Link>
          )) : (
            <div className="properties-wrapper__no-search-result">Nu s-au găsit rezultate care să corespundă criteriilor dvs!</div>
          )}
        </div>
        }
        </div>
        )
    }
}
export default withRouter(Properties);
