import React from 'react';
import './App.scss';
import Homepage from '../src/containers/Homepage/Homepage';
import Contact from '../src/containers/Contact/Contact';
import About from '../src/containers/About/About';
import FAQ from '../src/containers/FAQ/FAQ';
import Offer from '../src/containers/Offer/Offer';
import PropertyGridFull from '../src/containers/PropertyGridFull/PropertyGridFull';
import SingleProperty from '../src/containers/SingleProperty/SingleProperty';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import ScrollToTop from '../src/components/ScrollToTop/ScrollToTop';
import Blog from '../src/containers/Blog/Blog';
import Article from '../src/containers/Article/Article';
import Favourites from '../src/containers/Favourites/Favourites';
import Agent from '../src/containers/Agent/Agent';
import {JssProvider} from 'react-jss';
import CookieConsent from 'react-cookie-consent';
import EventEmitter from 'wolfy87-eventemitter';
import FavouritesBar from '../src/components/FavouritesBar/FavouritesBar';

window.favoriteEventEmiter = new EventEmitter();

class  App extends React.Component{

  render() {
    return (
      <JssProvider classNamePrefix="invest-imobiliare-">
        <div className="container-wrap">
          <Router>
            <div>
             <DefaultRoute exact path="/" component={Homepage}/>
             <DefaultRoute exact path="/contact" component={Contact}/>
             <DefaultRoute exact path="/despre-noi" component={About}/>
             <DefaultRoute exact path='/servicii' component={FAQ}/>
             <DefaultRoute exact path='/oferta-ta' component={Offer}/>
             <DefaultRoute exact path='/proprietati/:query?' component={PropertyGridFull}/>
             <DefaultRoute exact path='/proprietate/:id' component={SingleProperty}/>
             <DefaultRoute exact path='/blog' component={Blog}/>
             <DefaultRoute exact path='/article' component={Article}/>
             <DefaultRoute exact path='/favorite' component={Favourites}/>
             <DefaultRoute exact path='/agent' component={Agent}/>
             <CookieConsent
              location="bottom"
              flipButtons
              buttonText="Accept"
              style={{ background: '#bfbfbf', alignItems: 'center', justifyContent: 'space-around'}}
              buttonStyle={{ backgroundColor: '#90c923', color: 'white',  fontSize: '15px', fontWeight: 'bold' }}
              contentStyle={{ width: '70%', flexGrow: 0, flexBasis: 'auto' }}
              expires={150}
            >
              <p className="cookies">Acest site folosește cookies. Navigând în continuare vă exprimați
              acordul asupra folosirii cookie-urilor. <a className='cookie-link' href='/documents/Cookies_Invest_Imobiliare.pdf' target='_blank'>Aflati mai multe</a></p>

            </CookieConsent>
            </div>
          </Router>
       </div>
     </JssProvider>
    );
  }
};

const DefaultRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <div>
        <MetaTags>
          <meta
            name="description"
            content="Invest Imobiliare"
          />
          <meta
            name="keywords"
            content="Invest Imobiliare, casa de vanzare Iasi, vila de vanzare Iasi, vila individuala, duplex/ triplex, apartament de vanzare, camere, garsoniera, teren, vanzare Iasi, inchiriere Iasi, fara risc seismic, bloc nou Iasi, semidecomandat, decomandat, confort 1, etaj intermediar, comision 0, zona, imobil, toate facilitatile"
          />
          <meta
            property="og:title"
            content="Invest Imobiliare"
          />
          <meta
            property="og:url"
            content={`https://www.investimobiliare.ro${matchProps.location.pathname}`}
          />
        </MetaTags>
        <FavouritesBar/>
        <ScrollToTop />
        <Component {...matchProps} />
      </div>
    )}
  />
);

export default App;
